import { defineStore } from 'pinia'
import set from 'lodash/set'
import type { User } from '@/types'
import { ROLES, STATUSES } from '@/constants'
import { useIOStore } from '@/store/io'
import { useLibrariesStore } from '@/store/libraries'
import { useUiStore } from '@/store/ui'

type AuthState = {
  user: Partial<User>
  token?: string
  registerToken?: string
  resetPasswordToken?: string
  permissions: string[] // only codes
  userSearchHistory: any[]
  userImage?: string
  visitorToken?: string
}

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => ({
    visitorToken: '',
    token: '',
    user: {
      id: null,
      email: '',
      name: '',
      languages: [],
      image: '',
      two_factor_secret: '',
      status: null,
    },
    userSearchHistory: [],
    userImage: '',
    permissions: [],
  }),
  actions: {
    setRegisterToken(token: string) {
      this.registerToken = token
    },
    setResetPasswordToken(token: string) {
      this.resetPasswordToken = token
    },
    setUserEmail(email: string) {
      this.user.email = email
    },
    setUserId(id: number) {
      this.user.id = id
    },
    setUserSecret(secret: string | null) {
      this.user.two_factor_secret = secret
    },
    setToken(token: string) {
      this.token = token
    },
    setVisitorToken(token: string) {
      this.visitorToken = token
    },
    setUser(user: User) {
      if (user.role) {
        const permissions: string[] = toRaw(user.role?.permissions)
          ?.map((p) => p.name)
          .flat()
        const userPermissions: string[] = toRaw(user.permissions)
          ?.map((p) => p.name)
          .flat()
        user.role.permissions = []
        user.permissions = []
        this.permissions = [...permissions, ...userPermissions]
      }
      this.userSearchHistory = [...user.search_history]
      user.search_history = []
      this.user = { ...user, image: undefined }
      this.userImage = user?.image?.replace('https://lh3.googleusercontent.com/fife/', '')
      useConnectToWebsocket()
      useConnectToWhatsappWebsocket()
    },
    setUserProperty(property: string, value: any) {
      set(this.user, property, value)
    },
    async setUserStatus(status: number) {
      const ioStore = useIOStore()
      const prevStatus = this.user.status
      this.user.status = status
      if (this.getIsActiveForOutboundCall) {
        ioStore.activateCall()
      } else {
        ioStore.disableCall()
      }
      const response = await useUserStatuses()
      const onlineStatusId = Number(response.find((s) => s.code === STATUSES.ONLINE)?.id)
      const isUserStatusOnline = status === onlineStatusId

      const offlineStatusId = Number(response.find((s) => s.code === STATUSES.OFFLINE)?.id)
      const isUserStatusPrevOffline = prevStatus === offlineStatusId

      if (isUserStatusPrevOffline && isUserStatusOnline) this.checkUserWANotifications()
    },
    clearUser() {
      this.$reset()
    },
    async checkUserWANotifications() {
      const uiStore = useUiStore()
      try {
        const { data } = await useGetUserWANotifications()
        if (!data || (Array.isArray(data) && !data.length)) return
        let payload
        if (!Array.isArray(data)) {
          payload = Object.entries(data).map(([key, value]) => ({
            lead_name: value,
            lead_id: Number(key),
          }))[0]
        } else {
          payload = data
        }
        uiStore.showToast('whatsapp', { data: payload })
      } catch (error: any) {
        uiStore.showSnackBanner(error.message, 'error')
      }
    },
  },
  getters: {
    getRegisterToken: (state) => state.registerToken,
    getResetPasswordToken: (state) => state.resetPasswordToken,
    getUser: (state) => state.user,
    getIsLoggedIn: (state) => Boolean(state.token && state.user?.id),
    getToken: (state) => state.token,
    getRoleCode: (state) => state.user?.role?.code,
    getPermissions: (state) => state.permissions,
    getIsAgent: (state) => state.user?.role?.code === ROLES.AGENT,
    getIsActiveForOutboundCall: (state) => {
      const librariesStore = useLibrariesStore()
      const status = librariesStore.getStatuses.find((s) => s.id === state.user?.status)
      if (status?.code === STATUSES.ONLINE || status?.code === STATUSES.DONT_DISTURB) return true

      return false
    },
    getIsSalesManager: (state) => state.user?.role?.code === ROLES.MANAGER,
    getIsManager: (state) =>
      state.user?.role?.code === ROLES.MANAGER ||
      state.user?.role?.code === ROLES.ADMIN ||
      state.user?.role?.code === ROLES.MARKETER,
    getIsMarketer: (state) => state.user?.role?.code === ROLES.MARKETER,
    getIsAdmin: (state) => state.user?.role?.code === ROLES.ADMIN,
    getIsCeo: (state) => state.user?.role?.code === ROLES.CEO,
    getIsHrManager: (state) => state.user?.role?.code === ROLES.HR_MANAGER,
    getIsFinanceManager: (state) => state.user?.role?.code === ROLES.FINANCE_MANAGER,
    getIsSdr: (state) => state.user?.role?.code === ROLES.SDR,
    getIsLeadManager: (state) => state.user?.role?.code === ROLES.LEAD_MANAGER,
    getUserImage: (state) => state.userImage,
    getUserSearchHistory: (state) => state.userSearchHistory,
    getVisitorToken: (state) => state.visitorToken,
  },
  persist: {
    paths: ['token', 'user', 'permissions'],
  },
})
