<template>
  <div class="grid size-full grid-cols-1 gap-3 lg:grid-cols-2">
    <UiInputDatePicker
      v-model="modelValue.contract_form_date"
      name="dateRange"
      :start-with-placeholder="!modelValue.contract_form_date"
      :placeholder="$t('select_date')"
      label="Contract F *"
      compact
    />
    <UiInputTextField
      id="ref_number"
      v-model="modelValue.ref_number"
      placeholder="Add a ref number"
      name="ref_number"
      label="Reff №"
      compact
    />
    <UiInputTextField
      id="unit_owner"
      v-model="modelValue.unit_owner"
      placeholder="Unit owner"
      name="unit_owner"
      label="Unit owner"
      compact
    />
    <UiInputTextField
      id="selling_price"
      v-model="modelValue.selling_price"
      placeholder="Selling price"
      type="number"
      suffix="AED"
      name="selling_price"
      label="Selling price *"
      compact
    />
    <UiInputTextField
      id="unit_number"
      v-model="modelValue.unit_number"
      placeholder="Unit number"
      name="unit_number"
      label="Unit number *"
      compact
    />
    <UiInputTextField
      id="property_name"
      v-model="modelValue.property_name"
      placeholder="Property name"
      name="property_name"
      label="Property name"
      compacts
    />
    <UiInputSelect
      id="Emirates"
      v-model="modelValue.emirates"
      :items="[]"
      add-new
      name="emirates"
      placeholder="Emirates"
      label="Emirates"
      compact
    />
    <UiInputTextField
      id="property_address"
      v-model="modelValue.property_address"
      placeholder="Property address"
      name="property_address"
      label="Property address"
      compact
    />
  </div>
</template>

<script setup lang="ts">
import type { Validation } from '@vuelidate/core'
import type { DealCreationDTO } from '~/types'

type Props = {
  v: Validation
  activeTabIdIndex?: number
}

defineProps<Props>()

const modelValue = defineModel<Partial<DealCreationDTO>>({ required: true })
</script>

<style lang="scss" scoped></style>
