/* eslint-disable no-console */
import { io, Socket } from 'socket.io-client'
import { useAuthStore } from '@/store/auth'
import { useWhatsappStore } from '@/store/whatsapp'
import type { WhatsappMessage, WhatsappChat } from '@/types'

export const useConnectToWhatsappWebsocket = () => {
  const config = useRuntimeConfig()
  const authStore = useAuthStore()
  const whatsappStore = useWhatsappStore()
  if (!process.client || window.whatsappInstance) return

  const options = {
    transports: ['websocket'],
    auth: {
      token: `Bearer ${authStore.token}`,
    },
  }

  const socket: Socket = io(config.public.API_URL_WHATSAPP, options)
  setTimeout(() => {
    if (!socket.connected) whatsappStore.setDisconnected()
  }, 500)

  window.whatsappInstance = socket

  subscribeToQrCode()
  subscribeToHistorySync()
  subscribeToWhatsappReady()
  subscribeToWhatsappError()
  subscribeToWhatsappDisconnect()
  subscribeToWhatsappConnect()
  subscribeToCheckWAUser()
  subscribeToCheckPhoneUser()
  subscribeToReceiveChat()
  subscribeToReceiveMessage()
  subscribeToEditMessage()
  subscribeToUpdateUnreadCountChat()
  subscribeToAckUpdateChat()
  subscribeToReactMessage()
  subscribeToDeleteMessage()
  subscribeToScheduleMessage()
  subscribeToUpdateScheduleMessage()
  subscribeToEditScheduleMessage()
  subscribeToDeleteScheduleMessage()
}

const subscribeToQrCode = () => {
  const whatsappStore = useWhatsappStore()

  if (!window.whatsappInstance) return

  window.whatsappInstance.on('qr', (e: any) => {
    whatsappStore.setIntegration(false)
    whatsappStore.setQrCode(e.data.qr)
    whatsappStore.setLoading()
    if (whatsappStore.disconnected) {
      whatsappStore.setDisconnected(false)
    }
  })
}

const subscribeToHistorySync = () => {
  const whatsappStore = useWhatsappStore()
  window.whatsappInstance?.on('history_sync', () => {
    whatsappStore.setLoading(true)
  })
}

const subscribeToWhatsappReady = () => {
  const whatsappStore = useWhatsappStore()
  window.whatsappInstance?.on('ready', () => {
    triggerWACheckPhoneUser()
    whatsappStore.setIntegration(true)
    whatsappStore.setLoading()
    if (whatsappStore.disconnected) {
      whatsappStore.setDisconnected(false)
    }
  })
}

const subscribeToWhatsappError = () => {
  window.whatsappInstance?.on('error', (error) => {
    console.error('Whatsapp error:', error)
  })
}

const subscribeToWhatsappDisconnect = () => {
  const whatsappStore = useWhatsappStore()
  window.whatsappInstance?.on('disconnect', () => {
    console.log('Whatsapp disconnected')
    whatsappStore.setDisconnected()
  })
}

const subscribeToWhatsappConnect = () => {
  const whatsappStore = useWhatsappStore()
  window.whatsappInstance?.on('connect', () => {
    console.log('Whatsapp connected')
    if (whatsappStore.disconnected) {
      whatsappStore.setDisconnected(false)
    }
  })
}

const triggerWACheckPhoneUser = () => {
  const authStore = useAuthStore()
  window.whatsappInstance?.emit('has_same_phone_number', {
    phoneNumber: `${authStore.getUser.phones[0].code}${authStore.getUser.phones[0].phone}`,
  })
}

const subscribeToCheckPhoneUser = () => {
  const whatsappStore = useWhatsappStore()
  window.whatsappInstance?.on('has_same_phone_number', (e: { data: any }) => {
    whatsappStore.setValidPhone(e.data)
  })
}

export const triggerWALogout = () => {
  const whatsappStore = useWhatsappStore()
  window.whatsappInstance?.emit('logout_whatsapp')
  whatsappStore.setIntegration(false)
  whatsappStore.resetChat()
}

export const triggerWACheckUser = (body: string) => {
  window.whatsappInstance?.emit('is_valid_user', { phoneNumber: body })
}

const subscribeToCheckWAUser = () => {
  const whatsappStore = useWhatsappStore()
  window.whatsappInstance?.on('is_valid_user', (e: { data: any }) => {
    whatsappStore.setWAUser(e.data.isValid)
  })
}

export const triggerWACreateChat = (body: any) => {
  window.whatsappInstance?.emit('create_chat', body)
}

const subscribeToReceiveChat = () => {
  const whatsappStore = useWhatsappStore()
  window.whatsappInstance?.on('chat_created', (e: { data: WhatsappChat }) => {
    whatsappStore.setNewChat(e.data)
  })
}

export const triggerWASendMessage = (body: any) => {
  window.whatsappInstance?.emit('send_message', body)
}

export const triggerWAEditMessage = (body: any) => {
  window.whatsappInstance?.emit('edit_message', body)
}

const subscribeToReceiveMessage = () => {
  const whatsappStore = useWhatsappStore()
  window.whatsappInstance?.on('message_created', (e: { data: WhatsappMessage }) => {
    whatsappStore.setNewMessage(e.data)
  })
}

const subscribeToEditMessage = () => {
  const whatsappStore = useWhatsappStore()
  window.whatsappInstance?.on('message_edited', (e: { data: WhatsappMessage }) => {
    whatsappStore.updateMessage(e.data)
  })
}

export const triggerWASendChatSeen = (id: string) => {
  const payload = {
    whatsAppChatId: id,
  }
  window.whatsappInstance?.emit('chat_seen', payload)
}

const subscribeToUpdateUnreadCountChat = () => {
  const whatsappStore = useWhatsappStore()
  window.whatsappInstance?.on(
    'unread_count_updated',
    (e: { data: { chatWhatsappId: string; unreadCount: number } }) => {
      whatsappStore.updateUnreadCount(e.data)
    }
  )
}

const subscribeToAckUpdateChat = () => {
  const whatsappStore = useWhatsappStore()
  window.whatsappInstance?.on(
    'message_ack_updated',
    (e: { data: { message: WhatsappMessage; unreadCount: number } }) => {
      whatsappStore.updateChat(e.data)
    }
  )
}

export const triggerWAReactMessage = (body: any) => {
  window.whatsappInstance?.emit('react_message', body)
}

const subscribeToReactMessage = () => {
  const whatsappStore = useWhatsappStore()
  window.whatsappInstance?.on('message_reacted', (e: { data: WhatsappMessage }) => {
    whatsappStore.updateMessage(e.data)
  })
}

export const triggerWADeleteMessage = (body: any) => {
  window.whatsappInstance?.emit('delete_message', body)
}

const subscribeToDeleteMessage = () => {
  const whatsappStore = useWhatsappStore()
  window.whatsappInstance?.on('message_deleted', (e: any) => {
    whatsappStore.deleteMessage(e.data.messageId)
  })
}

export const triggerWARetrieveScheduleMessages = (body: any) => {
  window.whatsappInstance?.emit('retrieve_schedule_message', body)
}

export const triggerWASendScheduleMessage = (body: any) => {
  window.whatsappInstance?.emit('schedule_message', body)
}

const subscribeToScheduleMessage = () => {
  const whatsappStore = useWhatsappStore()
  window.whatsappInstance?.on('message_scheduled', (e: { data: WhatsappMessage }) => {
    whatsappStore.setNewMessage(e.data)
  })
}

const subscribeToUpdateScheduleMessage = () => {
  const whatsappStore = useWhatsappStore()
  window.whatsappInstance?.on('message_scheduled_ack', (e: any) => {
    whatsappStore.deleteMessage(e.data.messageId)
  })
}

const subscribeToEditScheduleMessage = () => {
  const whatsappStore = useWhatsappStore()
  window.whatsappInstance?.on('message_scheduled_edited', (e: { data: WhatsappMessage }) => {
    whatsappStore.updateMessage(e.data)
  })
}

const subscribeToDeleteScheduleMessage = () => {
  const whatsappStore = useWhatsappStore()
  window.whatsappInstance?.on('message_scheduled_deleted', (e: any) => {
    whatsappStore.deleteMessage(e.data.messageId)
  })
}

export const triggerWAEditScheduleMessage = (body: any) => {
  window.whatsappInstance?.emit('edit_schedule_message', body)
}

export const triggerWADeleteScheduleMessage = (body: any) => {
  window.whatsappInstance?.emit('delete_schedule_message', body)
}
