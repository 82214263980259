<template>
  <div>
    <div v-for="(deal, i) in modelValue.deals" :key="i" class="w-full">
      <div v-show="activeTabIdIndex === i" class="flex flex-col gap-4">
        <div class="flex flex-col gap-1">
          <h5>Documents for units</h5>
          <div v-if="modelValue.type_id" class="text-subhead-2 rounded-lg bg-primary-10 px-4 py-1 text-black-90">
            Upload your file as a .PDF and make sure it's less than 20 MB
          </div>
        </div>
        <Transition name="fade" mode="out-in">
          <div v-if="!modelValue.type_id" class="flex h-[92px] flex-col gap-2 rounded-lg bg-secondary-10 p-2" />
          <div v-else class="flex flex-col gap-4">
            <div class="flex min-h-10 flex-col items-center gap-3 lg:grid lg:grid-cols-2">
              <div
                v-for="file in deal.documents"
                :key="file.id"
                class="flex min-h-10 w-full flex-row items-center gap-3"
              >
                <div class="text-body-2 flex max-w-[140px] flex-1 flex-row items-center gap-1.5 text-black-60">
                  <UiIcon class="shrink-0" name="link" size="xs" />
                  <span>{{ getFileLabel(file) }}</span>
                </div>
                <div class="w-5/12">
                  <UiInputFile
                    :id="`${file.name}`"
                    v-model="file.file"
                    compact
                    :virtual-file-name="file.file_name"
                    icon="upload"
                    :delete-locally="false"
                    accept="image/*,application/pdf"
                    @file-removed="removeFile(deal, file)"
                    @update:model-value="updateName(file)"
                  />
                </div>
              </div>
            </div>
            <div class="flex min-h-10 flex-row items-center gap-1">
              <div class="text-body-2 flex max-w-[140px] flex-1 flex-row items-center gap-1.5 truncate text-black-60">
                <UiIcon class="shrink-0" name="link" size="xs" />
                <span>{{ $t('other_file') }}</span>
              </div>
              <div class="w-4/12">
                <UiInputTextField
                  id="other_file_name"
                  v-model="otherFile.name"
                  name="other_file_name"
                  :placeholder="$t('add_file_name')"
                  :error="useGetFieldErrors(v$, ['name'])"
                  compact
                />
              </div>
              <div class="w-5/12">
                <UiInputFile
                  id="other_file_file"
                  :model-value="otherFile.file"
                  :prevent-upload="!otherFile.name"
                  compact
                  icon="upload"
                  accept="image/*,application/pdf"
                  @attach="validateOtherFile"
                  @update:model-value="handleOtherFileUpload(deal, $event)"
                />
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { type Validation, useVuelidate } from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import type { DealCreationDTO, DealCreationUnitDTO, DealFile, LibraryItem } from '~/types'

import { useUiStore } from '~/store/ui'
import { FINANCE_FILE_TYPES } from '@/constants'

type Props = {
  v: Validation
  isOffPlan?: boolean
  activeTabIdIndex?: number
}

const props = defineProps<Props>()

watch(
  () => props.activeTabIdIndex,
  () => {
    const passportFile = getPassportFile(modelValue.value.deals)
    addDefaultFiles()

    modelValue.value.deals?.forEach((deal) => {
      const buyerPassport = deal.documents?.find((f) => f.type_id === buyerPassportCopyTypeId.value)

      if (passportFile && buyerPassport && !buyerPassport?.file) {
        buyerPassport.file = passportFile
        buyerPassport.file_name = passportFile.name
      }
    })
  }
)

const { t: $t } = useI18n()

const uiStore = useUiStore()

const modelValue = defineModel<Partial<DealCreationDTO>>({ required: true })

const fileTypes = ref<LibraryItem[]>([])

const reservationFormTypeId = ref<LibraryItem['id']>()
const buyerPassportCopyTypeId = ref<LibraryItem['id']>()
const otherFileTypeId = ref<LibraryItem['id']>()

// todo: missing contract form file and seller passport copy
const FILE_NAMES_MAP = {
  [FINANCE_FILE_TYPES.RESERVATION_FORM]: $t('reservation_form'),
  [FINANCE_FILE_TYPES.PASSPORT_COPY]: $t('buyer_passport'),
}

onMounted(() => getFileTypes())

const getFileLabel = (file: DealFile) => {
  return FILE_NAMES_MAP[file.type?.code || ''] || file.name
}

const getFileTypes = async () => {
  try {
    fileTypes.value = await useFinanceFileTypes()
    // TODO: missing contract form file and seller passport copy

    reservationFormTypeId.value = fileTypes.value.find((t) => t.code === FINANCE_FILE_TYPES.RESERVATION_FORM)?.id
    buyerPassportCopyTypeId.value = fileTypes.value.find((t) => t.code === FINANCE_FILE_TYPES.PASSPORT_COPY)?.id
    otherFileTypeId.value = fileTypes.value.find((t) => t.code === FINANCE_FILE_TYPES.OTHER)?.id

    otherFile.value.type_id = Number(otherFileTypeId.value)

    addDefaultFiles()
  } catch (error: any) {
    uiStore.showSnackBanner('Failed to fetch file types, please try again later', 'error')
  }
}

const addDefaultFiles = () => {
  if (!reservationFormTypeId.value || !buyerPassportCopyTypeId.value) {
    throw new Error('File types not found')
  }

  modelValue.value.deals?.forEach((deal) => {
    if (!deal.documents?.length) {
      const isReservationFormExists = deal.documents?.some(
        ({ type_id: typeId }) => typeId === Number(reservationFormTypeId.value)
      )
      const isBuyerPassportExists = deal.documents?.some(
        ({ type_id: typeId }) => typeId === Number(buyerPassportCopyTypeId.value)
      )

      if (!isBuyerPassportExists) {
        deal.documents?.unshift({
          id: crypto.randomUUID(),
          name: FILE_NAMES_MAP[FINANCE_FILE_TYPES.PASSPORT_COPY],
          type_id: Number(buyerPassportCopyTypeId.value),
          file: undefined,
          file_name: '',
        })
      }
      if (!isReservationFormExists) {
        deal.documents?.unshift({
          id: crypto.randomUUID(),
          name: FILE_NAMES_MAP[FINANCE_FILE_TYPES.RESERVATION_FORM],
          type_id: Number(reservationFormTypeId.value),
          file: undefined,
          file_name: '',
        })
      }
    }
  })
}

const removeFile = (deal: DealCreationUnitDTO, file: DealFile) => {
  const isOtherFile = file.type_id === otherFileTypeId.value

  if (isOtherFile) {
    deal.documents = deal.documents?.filter((f) => f.id !== file.id)
  } else {
    file.file_name = ''
    file.file = undefined
    file.id = crypto.randomUUID()
  }
}

const getDefaultOtherFile = () => ({
  id: crypto.randomUUID(),
  name: '',
  type_id: Number(otherFileTypeId.value),
  file_name: '',
  file: undefined,
})

const otherFile = ref<DealFile>(getDefaultOtherFile())

const rules = computed(() => ({
  name: { required: helpers.withMessage(' ', required) },
}))

const v$ = useVuelidate(rules, otherFile, { $scope: false })

const validateOtherFile = () => {
  v$.value.$validate()
}

const handleOtherFileUpload = (deal: DealCreationUnitDTO, file: File) => {
  otherFile.value.file = file
  otherFile.value.file_name = file.name

  deal.documents?.push(otherFile.value)

  otherFile.value = getDefaultOtherFile()

  v$.value.$reset()
}

const updateName = (dealFile: DealFile) => {
  dealFile.file_name = dealFile.file?.name || ''
}

const getPassportFile = (deals: DealCreationUnitDTO[] | undefined) => {
  if (!deals) {
    return undefined
  }
  let passportFile

  for (const deal of deals) {
    passportFile = deal.documents?.find(({ type_id: typeId }) => typeId === buyerPassportCopyTypeId.value)?.file
    if (passportFile) {
      return passportFile
    }
  }
  return passportFile
}
</script>

<style scoped></style>
