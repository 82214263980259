<template>
  <div class="flex flex-col gap-3">
    <div class="w-full">
      <UiInputDatePicker
        v-model="modelValue!.down_payment_due_date"
        name="dateRange"
        :start-with-placeholder="!modelValue!.down_payment_due_date"
        :placeholder="$t('select_date')"
        label="Down payment due date"
        compact
      />
    </div>
    <div class="flex flex-col items-center gap-3 lg:flex-row">
      <UiInputTextField
        id="down_payment_amount"
        v-model="modelValue!.down_payment_amount"
        :placeholder="$t('add_down_payment_amount')"
        name="down_payment"
        suffix="AED"
        type="number"
        label="Down payment, AED"
        class="w-full lg:w-1/2"
        compact
      />
      <UiInputTextField
        id="down_payment_amount_percentage"
        v-model="percentage"
        placeholder="%"
        name="down_payment"
        suffix="%"
        type="percentage"
        label="Down payment, %"
        class="w-full lg:w-1/2"
        compact
      />
    </div>

    <UiInputTextarea
      id="down_payment_note"
      v-model="modelValue!.down_payment_note"
      placeholder="Add a note"
      name="down_payment_note"
      label="Specify any notes"
      :start-rows="1"
      compact
      :max-length="50"
    />
  </div>
</template>

<script setup lang="ts">
import type { Validation } from '@vuelidate/core'
import type { DealCreationUnitDTO } from '@/types'

type Props = {
  v: Validation
}

defineProps<Props>()

const modelValue = defineModel<Partial<DealCreationUnitDTO>>({ required: true })

const percentage = computed<number>({
  get() {
    if (!modelValue.value?.down_payment_amount) return undefined

    const percent = (Number(modelValue.value?.down_payment_amount) / Number(modelValue.value?.unit_price)) * 100

    return percent ? Number(percent.toFixed(2)) : undefined
  },

  set(value: number) {
    const downPaymentAmount = (Number(modelValue.value?.unit_price) * value) / 100
    modelValue.value.down_payment_amount = Number(downPaymentAmount.toFixed(2))
  },
})
</script>

<style scoped></style>
