<template>
  <!-- Text Input -->
  <div :id="name" class="relative flex w-full flex-col items-start space-y-px" :class="{ 'mb-6': description }">
    <div class="flex w-full flex-row items-center justify-between">
      <h5 v-if="label" class="text-subhead-3 mx-4 text-black-80" :for="name">{{ label }}</h5>
      <p v-if="showLimit" class="text-caption-2 ml-auto text-black-60">{{ modelValue?.length || 0 }}/{{ maxLength }}</p>
    </div>
    <label
      class="relative flex w-full flex-row items-center justify-between gap-2 rounded-xl bg-white px-4 py-3 text-black-80"
      :class="compact ? 'h-10' : 'h-12'"
    >
      <UiIcon
        v-if="icon && iconPrefix"
        :name="icon"
        :class="[error ? 'text-black-60' : isInputFocused ? 'text-primary-120' : 'text-black-60']"
        class="peer/icon-prefix z-10"
      ></UiIcon>
      <small
        v-if="prefix"
        :class="[error ? 'text-black-60' : isInputFocused ? 'text-black-100' : 'text-black-60']"
        class="peer/prefix z-10"
        >{{ prefix }}</small
      >
      <!-- "text" property is for QA -->
      <input
        :id="name"
        :ref="(el) => (refs[name] = el)"
        v-model="shallowValue"
        :text="shallowValue"
        :name="name"
        :type="type === 'currency' || type === 'percentage' ? 'text' : type"
        :readonly="readOnly"
        :placeholder="placeholder"
        :disabled="disabled"
        :max-length
        :autocomplete="autocomplete ? 'on' : 'off'"
        class="peer z-10 h-[20px] w-full flex-1 border-none bg-transparent outline-none [appearance:textfield] placeholder:text-sm placeholder:font-normal placeholder:leading-5 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
        :class="{ 'placeholder:text-black-40': error, 'text-body-2': compact }"
        @focus="isInputFocused = true"
        @blur="handleBlur"
        @input="input"
      />
      <small
        v-if="suffix"
        class="peer/suffix z-10"
        :class="[error ? 'text-black-60' : isInputFocused ? 'text-black-100' : 'text-black-60']"
        >{{ suffix }}</small
      >
      <UiIcon
        v-if="icon && !iconPrefix"
        :name="icon"
        :class="[error ? 'text-black-60' : isInputFocused ? 'text-primary-120' : 'text-black-60']"
        class="peer/icon z-10"
      ></UiIcon>
      <div
        class="absolute left-0 z-0 size-full rounded-xl border-[1.5px] border-solid outline-none transition-colors duration-200"
        :class="[
          error
            ? 'border-error-100 peer-hover/icon-prefix:border-error-100 peer-hover/icon:border-error-100 peer-hover/prefix:border-error-100 peer-hover/suffix:border-error-100 peer-hover:border-error-100 peer-disabled:bg-black-05'
            : 'border-black-20 hover:border-primary-50 active:border-primary-120 peer-hover/icon-prefix:border-primary-50 peer-hover/icon:border-primary-50 peer-hover/prefix:border-primary-50 peer-hover/suffix:border-primary-50 peer-hover:border-primary-50  peer-focus:border-primary-120 peer-active:border-primary-120 peer-enabled:placeholder:text-black-100 peer-disabled:border-black-20 peer-disabled:bg-black-05',
          { '!border-none': !error && ghost && !modelValue && !isInputFocused },
        ]"
      ></div>
    </label>
    <div class="absolute -bottom-4 h-4 w-full" :class="[errorWrapperClass]">
      <transition name="fade" mode="out-in">
        <p
          v-if="error"
          :class="[{ '!w-max': inlineError }, errorBodyClass]"
          class="text-caption-2 mx-4 flex flex-row items-center justify-start text-error-100"
        >
          {{ error }}
        </p>
        <p v-else-if="description" class="text-caption-2 mx-4 flex flex-row items-center justify-start text-black-60">
          {{ description }}
        </p>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { watchDebounced } from '@vueuse/core'
import { DEFAULT_DEBOUNCE_INPUT_TIME } from '@/constants'

const emits = defineEmits(['update:modelValue', 'update:debounced', 'blur'])

type Props = {
  modelValue: string | number | undefined | null
  type?: string
  name: string
  label?: string
  placeholder?: string
  disabled?: boolean
  readOnly?: boolean
  description?: string
  icon?: string
  iconPrefix?: boolean
  prefix?: string
  suffix?: string
  error?: string
  compact?: boolean
  ghost?: boolean
  inlineError?: boolean
  errorBodyClass?: string
  errorWrapperClass?: string
  autocomplete?: boolean
  maxLength?: HTMLInputElement['maxLength']
  showLimit?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  type: 'text',
  label: '',
  placeholder: '',
  description: '',
  icon: '',
  suffix: '',
  prefix: '',
  error: '',
  errorBodyClass: '',
  errorWrapperClass: '',
  autocomplete: true,
  maxLength: undefined,
  pattern: undefined,
  showLimit: false,
})

const isInputFocused = ref(false)

const handleBlur = (event: FocusEvent) => {
  isInputFocused.value = false

  emits('blur', event)
}

const shallowValue = ref()

const parseToCurrencyFormat = () => {
  const parts = shallowValue.value.toString().split('.')
  const v = parts[0].replace(/\D/g, '')
  const dec = parts[1]
  const n = new Intl.NumberFormat('en-EN').format(v)
  shallowValue.value = dec !== undefined ? n + '.' + dec : n
}

// no matter the input, format to percentage, min 0 max 100
const parseToPercentageFormat = () => {
  // check if number is in cientific notation
  if (shallowValue.value && shallowValue.value.toString().includes('e')) {
    const decimalPlaces = ++shallowValue.value.toString().split('e')[1].split('-')[1]
    // parsefloat and fixed N decimal places the number has
    shallowValue.value = parseFloat(shallowValue.value).toFixed(decimalPlaces)
  }
  const parts = shallowValue.value.toString().split('.')
  const v = parts[0].replace(/\D/g, '')
  const dec = parts[1]
  const n = new Intl.NumberFormat('en-EN').format(v)
  shallowValue.value = dec !== undefined ? n + '.' + dec : n
  shallowValue.value = shallowValue.value.replace(/[^0-9.]/g, '')

  if (parseFloat(shallowValue.value) > 100) {
    shallowValue.value = '100'
  }
  if (parseFloat(shallowValue.value) < 0) {
    shallowValue.value = '0'
  }
}

watch(
  () => props.modelValue,
  (value) => {
    shallowValue.value = value
    if (shallowValue.value && props.type === 'currency') parseToCurrencyFormat()
    if (shallowValue.value && props.type === 'percentage') parseToPercentageFormat()
  },
  { immediate: true }
)

watchDebounced(
  shallowValue,
  () => {
    emits('update:debounced')
  },
  { debounce: DEFAULT_DEBOUNCE_INPUT_TIME }
)

const refs = {
  [props.name]: ref<HTMLInputElement>(),
}

const input = (event: Event) => {
  const target = event.target as HTMLInputElement

  if (props.maxLength && target.value.length > props.maxLength) {
    shallowValue.value = target.value.slice(0, props.maxLength)
  } else {
    shallowValue.value = target.value
  }

  if (props.type === 'currency') {
    parseToCurrencyFormat()
    emits('update:modelValue', parseFloat(shallowValue.value?.replace(/,/g, '')))
  } else if (props.type === 'percentage') {
    parseToPercentageFormat()
    emits('update:modelValue', parseFloat(shallowValue.value?.replace(/,/g, '')))
  } else {
    emits('update:modelValue', shallowValue.value)
  }
}
</script>

<style scoped></style>
