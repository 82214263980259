<template>
  <div>
    <h5 v-if="label" class="text-subhead-3 mx-4 text-left text-black-80" :for="name">{{ label }}</h5>
    <Transition name="fade" mode="out-in">
      <UiLoader v-if="loading" />
      <div v-else class="flex flex-row flex-wrap gap-1">
        <div
          v-for="item in items"
          :key="item.value"
          :class="[
            (multiple && modelValue.includes(item.value)) || (!multiple && modelValue === item.value)
              ? 'text-subhead-4 border border-solid border-primary-100 bg-primary-100 text-white'
              : 'text-body-2 border border-solid border-black-10 bg-white text-black-80',

            'h-10 flex-auto cursor-pointer select-none truncate rounded-xl px-2.5 py-2 text-center transition-all duration-200 ease-in-out',
          ]"
          @click="selectOption(item.value)"
        >
          {{ item.text }}
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import type { InputItem } from '~/types'
const emits = defineEmits(['update:modelValue'])

const modelValue = defineModel<string | string[] | undefined>({ required: true })

type Props = {
  items: InputItem[]
  name: string
  label?: string
  placeholder?: string
  disabled?: boolean
  error?: string
  multiple?: boolean
  loading?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  items: () => [],
  name: '',
  label: '',
  placeholder: '',
  disabled: false,
  error: '',
  multiple: false,
})

const selectOption = (value: any) => {
  if (props.multiple) {
    const index = modelValue.value.indexOf(value)
    if (index === -1) {
      modelValue.value.push(value)
    } else {
      modelValue.value.splice(index, 1)
    }
  } else {
    modelValue.value = value
  }

  emits('update:modelValue', modelValue.value)
}
</script>

<style scoped></style>
