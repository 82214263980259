import { isBlob } from '~/guards'
import { useUiStore } from '~/store/ui'

export default async (src: string | Blob, name: string) => {
  const uiStore = useUiStore()

  try {
    const file = isBlob(src) ? src : await useFileFromUrl(src, name, 'application/pdf')
    const url = URL.createObjectURL(file)

    const a = document.createElement('a')

    a.href = url
    a.download = name?.split('\\')?.pop()?.split('/')?.pop()!
    a.click()

    URL.revokeObjectURL(url)
    a.remove()
  } catch (error: any) {
    uiStore.showSnackBanner('Failed to download file, please try again later', 'error')
  }
}
