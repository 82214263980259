;(function () {
  const widgetContainer = document.getElementById('my-widget')
  if (!widgetContainer) return

  widgetContainer.innerHTML = `
  <div id="widget-container" style="position: fixed; bottom: 20px; right: 20px; z-index: 9999">
    <div id="widget" style="max-width: 400px; overflow: hidden; font-family: Helvetica; border-radius: 24px; border: 1px solid #e7e7e8">
        <div
          style="
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding: 32px 20px 16px 32px;
            background-color: #191919;
          "
        >
          <div style="margin-right: 10px">
            <h3
              id="widget-title"
              style="
                margin-bottom: 4px;
                color: #ca9163;
                font-weight: 700;
                font-size: 24px;
                line-height: 33px;
              "
            >
            </h3>
            <p id="widget-description" style="color: #e7e7e8; font-size: 16px; font-weight: 400; line-height: 18px">
              Contact our property consultant to get more details
            </p>
          </div>
          <button id="widget-close">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.0303 8.03033C17.3232 7.73744 17.3232 7.26256 17.0303 6.96967C16.7374 6.67678 16.2626 6.67678 15.9697 6.96967L12 10.9393L8.03033 6.96967C7.73744 6.67678 7.26256 6.67678 6.96967 6.96967C6.67678 7.26256 6.67678 7.73744 6.96967 8.03033L10.9393 12L6.96967 15.9697C6.67678 16.2626 6.67678 16.7374 6.96967 17.0303C7.26256 17.3232 7.73744 17.3232 8.03033 17.0303L12 13.0607L15.9697 17.0303C16.2626 17.3232 16.7374 17.3232 17.0303 17.0303C17.3232 16.7374 17.3232 16.2626 17.0303 15.9697L13.0607 12L17.0303 8.03033Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
        <div
          style="
            display: flex;
            align-items: flex-start;
            gap: 16px;
            padding: 16px 32px 32px;
            background-color: white;
          "
        >
          <img
            id="widget-image"
            src=""
            alt="widget-image"
            style="width: 66px; height: 66px; object-fit: cover; border-radius: 100%"
          />
          <div>
            <p style="margin-bottom: 12px; font-weight: 700; font-size: 16px; line-height: 22px">
              Property consultant
            </p>
            <div style="display: flex; align-items: center; gap: 8px">
              <button id="widget-whatsapp">
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_6486_188402)">
                    <mask
                      id="mask0_6486_188402"
                      style="mask-type: luminance"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="32"
                      height="32"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.27468 0.0157223C6.56104 0.04109 5.64127 0.0969219 5.22322 0.181299C4.58508 0.310165 3.98244 0.505548 3.48127 0.760983C2.89244 1.06107 2.3647 1.44394 1.90901 1.89892C1.45227 2.35478 1.06786 2.88301 0.766572 3.47272C0.511852 3.9712 0.316579 4.57018 0.187164 5.2047C0.101088 5.62697 0.0441611 6.55359 0.0183548 7.27183C0.00788982 7.5654 0.00252036 7.94532 0.00252036 8.12054L0 23.8769C0 24.0514 0.00531467 24.4315 0.0157248 24.7254C0.0410928 25.439 0.0969242 26.3587 0.181356 26.7768C0.310168 27.4149 0.505606 28.0176 0.760984 28.5187C1.06113 29.1076 1.44395 29.6352 1.89893 30.091C2.35478 30.5477 2.88307 30.9321 3.47272 31.2334C3.97126 31.4881 4.57023 31.6834 5.2047 31.8128C5.62697 31.8989 6.55364 31.9558 7.27189 31.9816C7.56535 31.9921 7.94532 31.9975 8.12054 31.9975L23.8769 32C24.0515 32 24.4315 31.9947 24.7253 31.9843C25.439 31.9589 26.3588 31.9031 26.7768 31.8186C27.4149 31.6898 28.0176 31.4943 28.5187 31.239C29.1076 30.9389 29.6353 30.556 30.091 30.1011C30.5477 29.6452 30.9321 29.117 31.2334 28.5273C31.4881 28.0287 31.6835 27.4298 31.8128 26.7953C31.899 26.373 31.9558 25.4463 31.9816 24.7281C31.9922 24.4346 31.9975 24.0547 31.9975 23.8795L32.0001 8.12306C32.0001 7.9485 31.9947 7.56847 31.9843 7.27463C31.9589 6.56104 31.9031 5.64127 31.8186 5.22322C31.6898 4.58508 31.4944 3.98244 31.239 3.48127C30.9389 2.89238 30.556 2.3647 30.1011 1.90895C29.6452 1.45233 29.1169 1.06786 28.5273 0.766571C28.0287 0.51185 27.4298 0.316523 26.7953 0.187162C26.373 0.101088 25.4464 0.0441589 24.7282 0.0184078C24.4346 0.00783348 24.0547 0.00251961 23.8795 0.00251961L8.12306 0C7.9485 0 7.56847 0.00525665 7.27468 0.0157223Z"
                        fill="white"
                      />
                    </mask>
                    <g mask="url(#mask0_6486_188402)">
                      <path
                        d="M7.27468 0.0157223C6.56104 0.04109 5.64127 0.0969219 5.22322 0.181299C4.58508 0.310165 3.98244 0.505548 3.48127 0.760983C2.89244 1.06107 2.3647 1.44394 1.90901 1.89892C1.45227 2.35478 1.06786 2.88301 0.766572 3.47272C0.511852 3.9712 0.316579 4.57018 0.187164 5.2047C0.101088 5.62697 0.0441611 6.55359 0.0183548 7.27183C0.00788982 7.5654 0.00252036 7.94532 0.00252036 8.12054L0 23.8769C0 24.0514 0.00531467 24.4315 0.0157248 24.7254C0.0410928 25.439 0.0969242 26.3587 0.181356 26.7768C0.310168 27.4149 0.505606 28.0176 0.760984 28.5187C1.06113 29.1076 1.44395 29.6352 1.89893 30.091C2.35478 30.5477 2.88307 30.9321 3.47272 31.2334C3.97126 31.4881 4.57023 31.6834 5.2047 31.8128C5.62697 31.8989 6.55364 31.9558 7.27189 31.9816C7.56535 31.9921 7.94532 31.9975 8.12054 31.9975L23.8769 32C24.0515 32 24.4315 31.9947 24.7253 31.9843C25.439 31.9589 26.3588 31.9031 26.7768 31.8186C27.4149 31.6898 28.0176 31.4943 28.5187 31.239C29.1076 30.9389 29.6353 30.556 30.091 30.1011C30.5477 29.6452 30.9321 29.117 31.2334 28.5273C31.4881 28.0287 31.6835 27.4298 31.8128 26.7953C31.899 26.373 31.9558 25.4463 31.9816 24.7281C31.9922 24.4346 31.9975 24.0547 31.9975 23.8795L32.0001 8.12306C32.0001 7.9485 31.9947 7.56847 31.9843 7.27463C31.9589 6.56104 31.9031 5.64127 31.8186 5.22322C31.6898 4.58508 31.4944 3.98244 31.239 3.48127C30.9389 2.89238 30.556 2.3647 30.1011 1.90895C29.6452 1.45233 29.1169 1.06786 28.5273 0.766571C28.0287 0.51185 27.4298 0.316523 26.7953 0.187162C26.373 0.101088 25.4464 0.0441589 24.7282 0.0184078C24.4346 0.00783348 24.0547 0.00251961 23.8795 0.00251961L8.12306 0C7.9485 0 7.56847 0.00525665 7.27468 0.0157223Z"
                        fill="url(#paint0_linear_6486_188402)"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M21.5466 18.1062C21.2615 17.9636 19.8603 17.2742 19.599 17.1792C19.3377 17.0841 19.1478 17.0366 18.9578 17.3218C18.7678 17.607 18.2216 18.2488 18.0553 18.439C17.889 18.6291 17.7228 18.6529 17.4378 18.5103C17.1528 18.3677 16.2344 18.0668 15.1458 17.0961C14.2985 16.3406 13.7265 15.4075 13.5602 15.1222C13.394 14.837 13.5425 14.6828 13.6852 14.5408C13.8134 14.4131 13.9702 14.208 14.1127 14.0416C14.2552 13.8752 14.3027 13.7564 14.3977 13.5663C14.4927 13.3761 14.4452 13.2097 14.374 13.0671C14.3027 12.9244 13.7327 11.522 13.4952 10.9515C13.2639 10.396 13.0289 10.4711 12.854 10.4624C12.6879 10.4542 12.4977 10.4524 12.3077 10.4524C12.1177 10.4524 11.8089 10.5237 11.5476 10.8089C11.2864 11.0942 10.5501 11.7835 10.5501 13.1859C10.5501 14.5883 11.5714 15.9432 11.7139 16.1333C11.8564 16.3235 13.7237 19.2014 16.5828 20.4356C17.2628 20.7292 17.7937 20.9044 18.2076 21.0358C18.8904 21.2526 19.5117 21.2221 20.0028 21.1487C20.5504 21.0669 21.6891 20.4594 21.9266 19.7938C22.164 19.1283 22.164 18.5578 22.0928 18.439C22.0216 18.3201 21.8316 18.2488 21.5466 18.1062ZM16.3462 25.2045H16.3424C14.6411 25.2038 12.9726 24.747 11.5169 23.8835L11.1707 23.678L7.58244 24.619L8.54023 21.1216L8.31477 20.763C7.36574 19.254 6.86452 17.5098 6.86523 15.719C6.86732 10.4933 11.1204 6.24187 16.35 6.24187C18.8823 6.24286 21.2627 7.23002 23.0527 9.02145C24.8427 10.8129 25.828 13.1942 25.827 15.7267C25.8248 20.9528 21.5718 25.2045 16.3462 25.2045ZM24.4152 7.66018C22.2616 5.50478 19.3976 4.3172 16.3462 4.31599C10.059 4.31599 4.94193 9.43106 4.93941 15.7183C4.93859 17.7281 5.46381 19.6899 6.46198 21.4191L4.84375 27.3281L10.8906 25.7425C12.5567 26.6509 14.4325 27.1296 16.3416 27.1304H16.3463H16.3463C22.6329 27.1304 27.7504 22.0147 27.753 15.7274C27.7541 12.6805 26.5688 9.81553 24.4152 7.66018Z"
                        fill="white"
                      />
                    </g>
                  </g>
                  <defs>
                    <linearGradient
                      id="paint0_linear_6486_188402"
                      x1="-3.43324e-05"
                      y1="32"
                      x2="-3.43324e-05"
                      y2="-3.67669e-05"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#25CF43" />
                      <stop offset="1" stop-color="#61FD7D" />
                    </linearGradient>
                    <clipPath id="clip0_6486_188402">
                      <rect width="32.0001" height="32" rx="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="widget-mini" style="cursor: pointer; display: none; overflow: hidden; width: 40px; height: 40px; border-radius: 100%; border: 1px solid #CA9163">
        <img
            id="widget-image"
            src=""
            alt="widget-image"
            style="width: 100%; height:100%; object-fit: cover"
          />
      </div>
    </div>
  `

  const widget = document.getElementById('widget')
  const widgetMini = document.getElementById('widget-mini')
  const closeButton = document.getElementById('widget-close')
  const widgetWhatsApp = document.getElementById('widget-whatsapp')
  const widgetTitle = document.getElementById('widget-title')
  const widgetDescription = document.getElementById('widget-description')
  const widgetImage = document.getElementById('widget-image')

  closeButton?.addEventListener('click', () => {
    widget.style.display = 'none'
    widgetMini.style.display = 'flex'
  })

  widgetMini?.addEventListener('click', () => {
    widget.style.display = 'block'
    widgetMini.style.display = 'none'
  })

  widgetWhatsApp?.addEventListener('click', () => {})

  async function fetchWidgetData() {
    try {
      // const response = await fetch('https://api.thrivestate.dev/api/v1.0/landing-widget/data') // Заміни на свій URL API
      // if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`)
      // console.log(response)
      // const data = await response.json()
      const data = {
        showWidget: true,
        title: 'Hello 👋🏻',
        description: 'Contact our property consultant to get more details',
      }
      processWidgetData(data)
    } catch (error) {
      widget.style.display = 'none'
      console.log(error)
    }
  }

  function processWidgetData(data) {
    const { showWidget, title, description } = data

    if (!showWidget) {
      widget.style.display = 'none'
      widgetMini.style.display = 'none'
      return
    }

    widgetTitle.innerHTML = title || 'Hello 👋🏻'
    widgetDescription.innerHTML = description || 'Contact our property consultant to get more details'

    widget.style.display = 'block'
  }

  fetchWidgetData()
})()
