export default defineNuxtRouteMiddleware((to) => {
  const user = useUser()

  if (!user) {
    if (to) {
      const redirectTo = useCookie('redirectTo')
      redirectTo.value = to.path
    }
    return navigateTo('/login')
  }

  if (!user?.phones?.length && to.path !== '/profile/setup') {
    return navigateTo('/profile/setup')
  }

  if (to.meta.permissions) {
    const routePermissions = to.meta.permissions as string[]

    if (!useHasPermissions(routePermissions)) {
      return navigateTo('/')
    }
  }
})
