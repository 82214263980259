<template>
  <div class="flex flex-col gap-3 px-3 py-4 md:gap-6 md:p-8">
    <div class="flex items-center justify-between">
      <div class="flex w-full flex-row items-center justify-between">
        <h4 class="text-subhead-3 md:text-h4">General Info</h4>
        <div class="hidden flex-row items-center md:flex">
          <UiButtonGhost
            v-for="(brochure, index) in item.brochures"
            :id="`download_brochure_${index}`"
            :key="index"
            class="!text-sm !text-black-100"
            @click="useDownloadFile(brochure.src, brochure.title)"
          >
            <UiIcon name="file" />
            <span class="underline">{{ brochure.title }}</span>
          </UiButtonGhost>
        </div>
        <UiMenuActions
          class="md:hidden"
          :width="300"
          :items="item.brochures?.map((brochure: Brochure) => ({
            text: brochure.title,
            value: {
                item: brochure,
                handler: (brochure: Brochure) => useDownloadFile(brochure.src, brochure.title),
            }
          }))"
          :name="`${item.complex_id}_actions`"
          @action="$event.handler($event.item)"
        >
          <template #activator="{ onClick, isOpen }">
            <UiButtonGhost id="quick_filters_activator" type="secondary" @click="onClick">
              Download brochures
              <UiIcon
                name="chevron-big-filled-up"
                :class="[{ 'rotate-180': isOpen }, 'transition-all duration-200']"
              ></UiIcon>
            </UiButtonGhost>
          </template>
        </UiMenuActions>
      </div>
    </div>
    <div class="flex flex-wrap gap-2 md:flex-nowrap md:gap-6">
      <div class="flex w-full flex-col gap-2 md:w-1/2">
        <PropertyTabsInfoCard v-if="minPriceM2 || maxPriceM2" label="Price Per m2">
          min {{ aedFormatter.format(minPriceM2) || 0 }} - max {{ aedFormatter.format(maxPriceM2) }}
          {{ item.price.currency }}
        </PropertyTabsInfoCard>
        <PropertyTabsInfoCard v-if="item.price.min?.length" label="Unit price"
          >from {{ aedFormatter.format(Number(item.price.min)) }} - to
          {{ aedFormatter.format(Number(item.price.max)) }} {{ item.price.currency }}</PropertyTabsInfoCard
        >
        <PropertyTabsInfoCard v-if="item.address" label="Location">{{ item.address }}</PropertyTabsInfoCard>
        <PropertyTabsInfoCard v-if="convertType" label="Property type">{{ convertType }}</PropertyTabsInfoCard>
      </div>
      <div class="flex w-full flex-col gap-2 md:w-1/2">
        <PropertyTabsInfoCard v-if="Number(item.for_sale_count)" label="Available units"
          >{{ item.for_sale_count }}
        </PropertyTabsInfoCard>
      </div>
    </div>
    <div class="w-full md:w-2/3">
      <ClientOnly>
        <Collapse :when="isFull" class="height-transition" :base-height="isMobile ? 0 : 135">
          <div ref="descriptionRef">{{ item.description.en }}</div>
        </Collapse>
        <UiButtonGhost v-if="needToHideText" id="show-more" class="!bg-transparent !p-0 md:mt-4" @click="showMore">
          {{ isMobile ? `Description` : `Show ${isFull ? 'less' : 'more'}` }}
          <UiIcon name="chevron-big-filled-down" :class="{ 'rotate-180': isFull }"></UiIcon>
        </UiButtonGhost>
      </ClientOnly>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Collapse } from 'vue-collapsed'
import minBy from 'lodash/minBy'
import maxBy from 'lodash/maxBy'
import type { PropertyTypes } from '@/types'
import type { Brochure } from '~/types/properties'

const aedFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  notation: 'compact',
  maximumFractionDigits: 3,
})

type Props = {
  item: PropertyTypes.PropertyDetailed
  isMobile?: boolean
}

const props = defineProps<Props>()

const isFull = ref(false)

const descriptionRef = ref<HTMLElement>()
const needToHideText = ref(false)

onMounted(() => {
  setTimeout(() => {
    needToHideText.value = Number(descriptionRef.value?.offsetHeight) > 135 || props.isMobile
  }, 200)
})

const minPriceM2 = computed(() => {
  const min = minBy(props.item.br_prices, (item: PropertyTypes.BedroomPrice) =>
    parseFloat(item.min_price_m2)
  )?.min_price_m2
  if (isNaN(min)) return 0
  return min
})
const maxPriceM2 = computed(() => {
  const max = maxBy(props.item.br_prices, (item: PropertyTypes.BedroomPrice) =>
    parseFloat(item.max_price_m2)
  )?.max_price_m2
  if (isNaN(max)) return 0
  return max
})

const convertType = computed(() => {
  return props.item.type
    ?.split('_')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
})

const showMore = () => {
  isFull.value = !isFull.value
}
</script>

<style scoped></style>
