<template>
  <UiInputCustomMenu
    v-if="paymentTokens.length"
    ref="menu"
    name="lead-payment-tokens"
    menu-classes="border-none bg-transparent"
  >
    <template #activator="{ onClick }">
      <div
        class="flex min-h-8 items-center gap-3 rounded-xl border border-additional-3-100 bg-additional-3-10 px-3 py-1"
      >
        <UiButtonBase
          v-if="isOnlyOnePaymentToken && paymentTokens[0].type.code === PAYMENT_TOKEN_CODES.MANUAL"
          id="add_confirmation"
          size="small"
          class="!whitespace-nowrap"
        >
          {{ $t('add_confirmation') }}
        </UiButtonBase>
        <I18n-t keypath="reservation_lead_description" tag="div" class="text-body-2" :plural="paymentTokens.length">
          <template #unit>
            <span class="text-subhead-4">
              <span>
                <span v-if="!isOnlyOnePaymentToken">{{ paymentTokens.length }}&nbsp;</span>
                <span class="inline-block first-letter:capitalize">
                  {{ unitName }}
                </span>
              </span>
            </span>
          </template>
        </I18n-t>
        <UiButtonGhost
          v-if="!isOnlyOnePaymentToken"
          id="lead-payment-tokens-more"
          class="text-subhead-3 text-primary-100"
          @click="onClick"
        >
          {{ $t('more') }}
        </UiButtonGhost>
        <UiTimer
          v-else
          class="text-body-2 bg-white"
          :end-date="paymentTokens[0].expires_at"
          @started="areTokensVisible = true"
          @ended="handleEnd(paymentTokens[0].id)"
        />
      </div>
    </template>

    <template v-if="!isOnlyOnePaymentToken" #content>
      <div class="relative flex w-full rounded-xl border border-primary-120 bg-white py-3">
        <div class="styled-scrollbar flex max-h-[360px] w-full flex-col gap-3 overflow-auto px-3">
          <LeadUnitPaymentToken
            v-for="(paymentToken, index) in paymentTokens"
            :key="paymentToken.id"
            class="border-black-10 [&:not(:last-child)]:border-b [&:not(:last-child)]:pb-3"
            :payment-token="paymentToken"
            :closable="!index"
            @close="handleClose"
            @ended="handleEnd"
          />
        </div>
      </div>
    </template>
  </UiInputCustomMenu>
</template>

<script setup lang="ts">
import { isFuture, parseISO } from 'date-fns'
import type { UiInputCustomMenu } from '#build/components'
import { useUiStore } from '~/store/ui'
import type { Lead } from '~/types'
import type { UnitPaymentToken } from '~/types/properties'
import { PAYMENT_TOKEN_CODES } from '~/constants'

const props = defineProps<{
  leadId: Lead['id']
}>()

const getActiveTokens = (tokens: UnitPaymentToken[]) => {
  return tokens.filter(({ expires_at: expiresAt }) => isFuture(parseISO(expiresAt)))
}

const { t: $t } = useI18n()

const unitName = computed(() => {
  if (paymentTokens.value.length > 1) {
    return $t('unit', paymentTokens.value.length)
  }

  return paymentTokens.value[0]?.unit.unit_number
})

const isOnlyOnePaymentToken = computed(() => paymentTokens.value.length === 1)

const menu = ref<InstanceType<typeof UiInputCustomMenu> | null>(null)

const handleClose = () => {
  menu.value?.closeMenu()
}

const areTokensVisible = ref(false)

const handleEnd = (id: UnitPaymentToken['id']) => {
  paymentTokens.value = paymentTokens.value.filter(({ id: paymentTokenId }) => paymentTokenId !== id)
}

const uiStore = useUiStore()

const paymentTokens = ref<UnitPaymentToken[]>([])

const FAIL_MESSAGE = 'Failed to get leads unit payment tokens, please try again later'

const getLeadUnitPaymentTokens = async (id: Lead['id']) => {
  try {
    const { data } = await useGetLeadActiveUnitPaymentTokens(id)

    paymentTokens.value = getActiveTokens(data)
  } catch {
    uiStore.showSnackBanner(FAIL_MESSAGE, 'error')
  }
}

onMounted(() => {
  getLeadUnitPaymentTokens(props.leadId)
})
</script>

<style scoped></style>
