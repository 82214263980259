<template>
  <div
    v-if="modelValue"
    class="fixed inset-x-0 top-0 z-50 size-full overflow-y-auto overflow-x-hidden bg-black-100/30 backdrop-blur"
  >
    <div class="m-auto flex size-full flex-1 flex-row items-stretch justify-start" :class="glued ? '' : 'p-6'">
      <div
        class="relative ml-auto flex w-10/12 flex-col justify-between bg-white shadow"
        :class="{
          'sm:w-6/12 lg:w-5/12 xl:w-4/12': size === 'small',
          'sm:w-8/12 lg:w-6/12 xl:w-5/12': size === 'medium',
          'sm:w-8/12 lg:w-7/12 xl:w-6/12': size === 'big',
          'sm:w-9/12 lg:w-8/12 xl:w-7/12': size === 'large',
          'w-full': size === 'fullscreen',
          'rounded-r-3xl': !glued,
          'rounded-l-3xl': size !== 'fullscreen',
        }"
      >
        <div
          class="absolute -left-14 top-8 flex size-10 cursor-pointer items-center justify-center rounded-full bg-white"
          @click="$emit('update:modelValue', false)"
        >
          <UiIcon name="big-close" />
        </div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const emits = defineEmits(['update:modelValue'])

type Props = {
  modelValue: boolean
  size?: 'small' | 'medium' | 'big' | 'large' | 'fullscreen'
  glued?: boolean
}

withDefaults(defineProps<Props>(), {
  size: 'small',
  glued: false,
})

onMounted(() => {
  document.onkeydown = (e) => {
    e = e || window.event
    if (e.key === 'Escape') emits('update:modelValue', false)
  }
  // lock scroll
  document.body.style.overflow = 'hidden'
})

onUnmounted(() => {
  document.onkeydown = null
  // unlock scroll
  document.body.style.overflow = 'auto'
})
</script>

<style scoped></style>
