<template>
  <UiPopup
    :model-value="modelValue"
    :title="$t('confirm_deleting_agent_title')"
    :description="$t('confirm_deleting_agent_message')"
    :primary-button-text="$t('delete')"
    :secondary-button-text="$t('close')"
    size="small"
    :loading="loading"
    @close="emits('cancel')"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="submit"
    @cancel="emits('cancel')"
  >
  </UiPopup>
</template>

<script setup lang="ts">
const emits = defineEmits(['update:modelValue', 'confirm', 'cancel'])

type Props = {
  modelValue: boolean
}

withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const loading = ref(false)

const submit = () => {
  emits('confirm')
  emits('update:modelValue', false)
}
</script>

<style scoped></style>
