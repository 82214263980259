<template>
  <UiPopup
    :model-value="modelValue"
    title="Successful test call"
    description="You can add this number to the Numbers Admin Panel."
    primary-button-text="Add number"
    size="small"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="$emit('input'), emits('update:modelValue', false)"
  >
  </UiPopup>
</template>

<script setup lang="ts">
const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
}

withDefaults(defineProps<Props>(), {
  modelValue: false,
})
</script>
