import { useLibrariesStore } from '@/store/libraries'
import type { ApiResponse, PaginationResponse, Pipeline } from '@/types'

export const useGetPipelines = () => useAPIFetch('/pipelines')

export const useGetPipelineById = async (id: number): Promise<Pipeline> => {
  const { data, error } = await useAPIFetch(`/pipelines/${id}`, {
    method: 'get',
  })
  if (error.value) throw createError(error.value)
  return toRaw((data.value as any)?.pipeline)
}

export const useGetPipelineByCode = async (code: string): Promise<Pipeline> => {
  const librariesStore = useLibrariesStore()
  const cachedPipeline = librariesStore.pipelines.find((p) => p.code === code)
  if (cachedPipeline) {
    return cachedPipeline
  }
  const { data, error } = await useAPIFetch(`/pipelines/code/${code}`, {
    method: 'get',
  })
  if (error.value) throw createError(error.value)
  const pipeline = toRaw((data.value as any)?.pipeline)
  librariesStore.addPipeline(pipeline)
  return pipeline
}

export const useGetPipelineLeads = async (id: number, query: Record<string, any>): Promise<PaginationResponse> => {
  const { data, error } = await useAPIFetch<PaginationResponse>(`/pipelines/${id}/leads`, {
    query,
    stringifyOptions: { arrayFormat: 'comma' },
  })

  if (error.value) throw createError(error.value)

  return toRaw(data.value)
}

export const useGetTablePipelineLeads = async (query: Record<string, any>) => {
  const { data, error } = await useAPIFetch(`/pipelines/sales/leads`, {
    query,
  })

  if (error.value) throw createError(error.value)

  return toRaw(data.value)
}

export const useGetArchivedLeads = async (query: Record<string, any>): Promise<PaginationResponse> => {
  const { data, error } = await useAPIFetch<PaginationResponse>('/pipelines/archive/leads', {
    query,
  })

  if (error.value) throw createError(error.value)

  return toRaw(data.value)
}

export const useGetAllLeads = async (query: Record<string, any>): Promise<PaginationResponse> => {
  const { data, error } = await useAPIFetch<PaginationResponse>('/pipelines/leads', {
    query,
  })

  if (error.value) throw createError(error.value)

  return toRaw(data.value)
}

export const useGetNextLead = async (id: number, leadId: number, filters: any, gridView: boolean) => {
  return await useAPIFetch<ApiResponse>(`pipelines/${id}/leads/next`, {
    query: { ...filters, current_lead_id: leadId, is_kanban: gridView },
    stringifyOptions: { arrayFormat: 'comma' },
  })
}

export const usePipelineSalesQuickFilters = async () =>
  await useAPIFetch(`/dictionary/pipeline_sales_leads_quick_filter_types`)

export const useArchiveQuickFilters = async () =>
  await useAPIFetch(`/dictionary/pipeline_archive_leads_quick_filter_types`)

export const exportLeadsTable = async (filters: any, headers: string[]) => {
  const query = { ...filters, columns: headers.join(','), format: 'csv' }

  return await new Promise((resolve, reject) => {
    useAPIFetch(`/pipelines/sales/leads/export`, {
      query,
      onResponse({ response }) {
        if (!response.ok) reject(createError(response._data))
        const filename = response.headers.get('Content-Disposition')?.split('filename=')[1]?.replaceAll('"', '')

        resolve({
          filename,
          data: response._data,
        })
      },
      onResponseError({ response }) {
        createError(response._data)
      },
    })
  })
}

export const exportLeadsArchiveTable = async (filters: any, headers: string[]) => {
  const query = { ...filters, columns: headers.join(','), format: 'csv' }

  return await new Promise((resolve, reject) => {
    useAPIFetch(`/pipelines/archive/leads/export`, {
      query,
      onResponse({ response }) {
        if (!response.ok) reject(createError(response._data))
        const filename = response.headers.get('Content-Disposition')?.split('filename=')[1]?.replaceAll('"', '')

        resolve({
          filename,
          data: response._data,
        })
      },
      onResponseError({ response }) {
        reject(createError(response._data))
      },
    })
  })
}

export const useGetPipelineLeadsCount = async (id: number) => await useAPIFetch(`pipelines/${id}/leads/count`)
