<template>
  <form id="lead-form" class="h-full" @submit.prevent>
    <h5 class="text-subhead-2 mb-4 text-start text-black-90">{{ $t('create_new_lead') }}:</h5>
    <UiInputTextField
      v-model="lead.name"
      label="Lead name"
      name="Name"
      placeholder="Lead name"
      class="mb-3"
      :error="useGetFieldErrors(v$, ['name'])"
    />

    <UiInputTextField
      v-model="lead.email"
      label="Email"
      name="Email"
      placeholder="Lead email"
      class="mb-3"
      :error="useGetFieldErrors(v$, ['email'])"
    />

    <UiInputSelect
      v-model="lead.language_id"
      :items="languageItems"
      label="Language"
      name="Language"
      placeholder="Choose language"
      class="mb-4"
      :error="useGetFieldErrors(v$, ['language_id'])"
    />

    <UiInputPhone
      v-model="lead"
      class="mb-6"
      name="Phone"
      label="Phone number"
      :placeholder="!validatePhone() ? 'Add number' : 'Number'"
      :error="useGetFieldErrors(v$, ['phone_country_id', 'phone'])"
      @update:model-value="preselectCountry"
    />
    <div class="flex items-center justify-end">
      <UiButtonBase id="cancel-expense-item" class="mr-4" type="secondary" :disabled="loading" @click="emits('cancel')">
        {{ $t('cancel') }}
      </UiButtonBase>
      <UiButtonBase id="save-expense-item" :disabled="loading" @click="submit">
        {{ $t('create') }}
      </UiButtonBase>
    </div>
  </form>
</template>

<script setup lang="ts">
import omitBy from 'lodash/omitBy'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, email } from '@vuelidate/validators'
import { useUiStore } from '~/store/ui'
import { useAuthStore } from '~/store/auth'
import type { InputItem, Lead, Pipeline } from '@/types'
import { PIPELINES, LEAD_STRATEGIES } from '@/constants'

const emits = defineEmits<{
  (e: 'created', lead: Lead): void
  (e: 'cancel'): void
}>()

const authStore = useAuthStore()

type Props = {
  modelValue: boolean
}
defineProps<Props>()

const user = useUser()

const DIRECT_CONTACT_ID = 24

const lead = ref<Partial<Lead>>({
  name: '',
  email: '',
  phone: null,
  phone_country_id: null,
  lead_source_id: DIRECT_CONTACT_ID,
  assignee_id: user?.id!,
})

const loading = ref(false)
const poolPipeline = ref<Pipeline>()
const salesPipeline = ref<Pipeline>()
const countryItems = ref<InputItem[]>([])
const languageItems = ref<InputItem[]>([])
const strategiesItems = ref<InputItem[]>([])

const validatePhone = () => {
  return useValidatePhone(+lead.value.phone, lead.value.phone_country_id)
}

const rules = computed(() => ({
  name: { required: helpers.withMessage('The name is required', required) },
  email: {
    email: helpers.withMessage('The email is not valid', email),
    required: helpers.withMessage('Email is required', required),
  },
  phone: {
    required: helpers.withMessage('Phone is required', required),
    validatePhone: helpers.withMessage('Phone number is not valid', validatePhone),
  },
  phone_country_id: {
    required: helpers.withMessage('Phone country is required', required),
    validatePhone: helpers.withMessage('Phone number is not valid', validatePhone),
  },
  country_id: { required: helpers.withMessage('Country is required', required) },
  language_id: { required: helpers.withMessage('Language is required', required) },
}))

const v$ = useVuelidate(rules, lead)

const preselectCountry = () => {
  if (!lead.value.country_id) {
    lead.value.country_id = lead.value.phone_country_id
  }
}

const getCountries = async () => {
  countryItems.value = await useGetCountriesItems()
}

const getLanguages = async () => {
  languageItems.value = await useGetLanguageItems()
}
const getPipelines = async () => {
  try {
    const sales = await useGetPipelineByCode(PIPELINES.SALES)
    poolPipeline.value = sales
    salesPipeline.value = sales
  } catch {
    uiStore.showSnackBanner('Failed to fetch pipelines, please try again later', 'error')
  }
}

const getStrategies = async () => {
  try {
    const strategies = await useCreationLeadStrategies()
    strategiesItems.value = strategies.map((s) => ({ text: s.name, value: s.id, code: s.code }))
    lead.value.save_strategy = strategiesItems.value.find((s) => s.code === LEAD_STRATEGIES.AGENT)?.value
  } catch {
    uiStore.showSnackBanner('Failed to fetch lead strategies, please try again later', 'error')
  }
}

onNuxtReady(async () => await Promise.all([getCountries(), getLanguages(), getPipelines(), getStrategies()]))

if (authStore.getIsAgent && user?.id) {
  lead.value.assignee_id = user?.id
}

const uiStore = useUiStore()

const createLead = async (lead: Partial<Lead>) => {
  try {
    const { data } = await useCreateLead(lead)

    if (data.value?.data) {
      emits('created', data.value?.data)
    }
  } catch {
    uiStore.showSnackBanner('Failed to create lead, please try again later', 'error')
  }
}

const submit = async () => {
  const isValid = await v$.value.$validate()
  if (!isValid) return

  try {
    loading.value = true
    lead.value.pipeline_id = poolPipeline.value?.id

    const localLead = omitBy(toRaw({ ...lead.value, phone: lead.value.phone?.toString() }), (f) => !f || f === 'hidden')
    await createLead(localLead)
  } catch {
    uiStore.showSnackBanner('Failed to create lead, please try again later', 'error')
  } finally {
    loading.value = false
  }
}
</script>

<style scoped></style>
