import omitBy from 'lodash/omitBy'

export const useGetGlobalSearch = async (params: Object) => {
  const query = omitBy(toRaw(params), (f) => !f)

  const { data, error } = await useAPIFetch(`/search/global?`, {
    query,
  })

  if (error.value) throw createError(error.value)

  return data.value as any
}

export const usePostRecentlyGlobalSearch = async (body: object) => {
  await useAPIFetch(`/users/search_history`, {
    method: 'POST',
    body,
  })
}
