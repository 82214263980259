<template>
  <UiSidePanelForm
    :model-value="modelValue"
    title="Lead Details"
    description="Lead details information can be edited or updated at any time."
    primary-button-text="Save"
    secondary-button-text="Cancel"
    :loading="loading"
    :disabled="saving"
    @update:model-value="$emit('update:modelValue', false)"
    @confirm="submit"
  >
    <div>
      <div v-if="localLead.step?.name === 'Failed'" class="bg-secondary-05 p-4 text-sm text-additional-3-100">
        <div class="flex w-max items-center rounded-lg bg-additional-3-10 px-3 py-1.5 font-semibold">
          <UiIcon name="alert-circle" class="mr-1 size-4"></UiIcon>
          Failure
        </div>
        <p class="mt-3">
          Lead with failed phone number was created. The original number is
          <span class="font-semibold">{{ duplicateWrongPhone }}</span
          >.
        </p>
      </div>
      <form class="h-full" @submit.prevent>
        <h5 class="text-subhead-1 mb-4 text-black-60">Lead contacts</h5>
        <template v-if="isSelectPhoneTypeVisible">
          <p class="text-body mb-3">
            Outgoing calls goes to primary number. You can change the primary number and outgoing calls will be made to
            it with priority.
          </p>
          <UiInputRadio
            id="phone_via_id"
            v-model="localLead.phone_via_id"
            vertical
            input-class="mt-5"
            class="mb-8"
            :items="phoneSelectOptions"
          >
            <template #label="{ item }">
              <UiInputPhone
                v-model="localLead[item.field]"
                name="Phone"
                :label="item.label"
                :disabled="item.disabled"
                placeholder="Add a number"
                :error="useGetFieldErrors(v$, item.fieldErrors)"
              />
            </template>
          </UiInputRadio>
        </template>
        <UiInputPhone
          v-else
          v-model="localLead.lead_phone"
          name="Phone"
          label="Phone number"
          placeholder="Add a number"
          class="mb-8"
          :error="useGetFieldErrors(v$, ['lead_phone.phone', 'lead_phone.phone_country_id'])"
        />

        <h5 class="text-subhead-1 mb-4 text-black-60">Lead details</h5>
        <UiInputTextField
          v-model="localLead.name"
          label="Lead name *"
          name="Name"
          placeholder="Lead name"
          class="mb-4"
          :error="useGetFieldErrors(v$, ['name'])"
        />
        <UiInputTextField
          v-model="localLead.email"
          label="Email"
          name="email"
          placeholder="Email"
          class="mb-4"
          :error="useGetFieldErrors(v$, ['email'])"
        />
        <UiInputSelect
          v-model="localLead.country_id"
          :items="useSerializeLibraryItems(librariesStore.getCountries)"
          label="Country *"
          name="country"
          placeholder="Choose country"
          class="mb-4"
          :error="useGetFieldErrors(v$, ['country_id'])"
        />
        <UiInputSelect
          v-model="localLead.language_id"
          :items="useSerializeLibraryItems(librariesStore.getLanguages)"
          label="Language *"
          name="language"
          placeholder="Language"
          class="mb-4"
          :error="useGetFieldErrors(v$, ['language_id'])"
        />
        <UiInputSelect
          v-model="localLead.timezone_id"
          :items="useSerializeLibraryItems(librariesStore.getTimezones)"
          align-right
          label="Timezone"
          name="Timezone"
          placeholder="Select a timezone"
          class="mb-4"
        />
      </form>
    </div>
  </UiSidePanelForm>
</template>

<script setup lang="ts">
import omitBy from 'lodash/omitBy'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, email } from '@vuelidate/validators'
import type { InputItem, Lead, LibraryItem, Phone } from '@/types'
import { useUiStore } from '~/store/ui'
import { useLibrariesStore } from '~/store/libraries'
import { LEAD_PHONE_TYPES_CODES } from '~/constants'

const uiStore = useUiStore()
const librariesStore = useLibrariesStore()

const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
  leadId: number
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const loading = ref(true)
const saving = ref(false)
const leadPhoneTypes = ref<LibraryItem[]>([])
const lead = ref<Lead>()

onNuxtReady(async () => {
  loading.value = true
  await Promise.all([getLead(), useCountries(), useLanguages(), useTimezones(), getLeadPhoneTypes()])
  loading.value = false
})

type LocalLead = Partial<Lead> & {
  phone_via_id: LibraryItem['id']
  lead_phone: Phone
  representative_phone: Phone
  partner_phone: Phone
}

const localLead = ref<LocalLead>({})

const getLead = async () => {
  try {
    lead.value = await useGetLead(props.leadId)

    localLead.value = {
      ...lead.value,
      phone_country_id: lead.value.phone_country?.id,
      country_id: lead.value.country?.id,
      language_id: lead.value.language?.id,
      phone_via_id: lead.value.phone_via?.id,
      partner_phone: {
        phone: lead.value.partner?.phone,
        phone_country_id: lead.value.partner?.phone_country?.id,
      },
      representative_phone: {
        phone: lead.value.representative?.phone,
        phone_country_id: lead.value.representative?.phone_country.id,
      },
      lead_phone:
        !useGetShowPhoneLead(lead.value) || useGetPhoneHide(lead.value)
          ? {
              phone: null,
              phone_country_id: null,
            }
          : {
              phone: Number(lead.value.phone),
              phone_country_id: lead.value.phone_country.id,
            },
    }
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

const { isCommunicationWayPartner, isCommunicationWayRepresentative } = useLeadStatus(lead)

const isSelectPhoneTypeVisible = computed(
  () => isCommunicationWayPartner.value || isCommunicationWayRepresentative.value
)

const OPTION_MAP = {
  [LEAD_PHONE_TYPES_CODES.VIA_PARTNER]: {
    label: 'Partner phone number',
    disabled: true,
    field: 'partner_phone',
    fieldErrors: ['partner_phone.phone', 'partner_phone.phone_country_id'],
  },
  [LEAD_PHONE_TYPES_CODES.VIA_REPRESENTATIVE]: {
    label: 'Representative phone number',
    disabled: false,
    field: 'representative_phone',
    fieldErrors: ['representative_phone.phone', 'representative_phone.phone_country_id'],
  },
  [LEAD_PHONE_TYPES_CODES.PRIMARY]: {
    label: 'Lead phone number',
    disabled: false,
    field: 'lead_phone',
    fieldErrors: ['lead_phone.phone', 'lead_phone.phone_country_id'],
  },
}

const phoneSelectOptions = computed<(InputItem & { field: string; fieldErrors: string[]; label: string })[]>(() => {
  const getFormattedOption = (way: LibraryItem) => {
    const option = OPTION_MAP[way.code as keyof typeof OPTION_MAP]

    if (!option) {
      throw new Error(`Unknown communication way: ${way.code}`)
    }

    return {
      ...way,
      value: way.id,
      text: way.name,
      ...option,
    }
  }

  const PHONE_SELECT_MAP = {
    [LEAD_PHONE_TYPES_CODES.PRIMARY]: true,
    [LEAD_PHONE_TYPES_CODES.VIA_PARTNER]: isCommunicationWayPartner.value,
    [LEAD_PHONE_TYPES_CODES.VIA_REPRESENTATIVE]: isCommunicationWayRepresentative.value,
  }

  return leadPhoneTypes.value
    .filter(({ code }) => {
      return PHONE_SELECT_MAP[code as keyof typeof PHONE_SELECT_MAP]
    })
    .sort(({ code }) => (code === LEAD_PHONE_TYPES_CODES.PRIMARY ? 1 : -1))
    .map(getFormattedOption)
})

const getLeadPhoneTypes = async () => {
  leadPhoneTypes.value = (await useLeadPhoneTypes()) || []
}

let duplicateWrongPhone: string | undefined | number | null
if (localLead.value.step?.name === 'Failed') {
  duplicateWrongPhone = localLead.value.phone
}

const validatePhone = (number?: number | null, countryId?: number | null) => {
  if (!number || !countryId) {
    return false
  }

  return useValidatePhone(+number!, countryId)
}

const PHONE_NUMBER_NOT_VALID = 'Phone number is not valid'

const rules = computed(() => ({
  name: { required: helpers.withMessage('The name is required', required) },
  email: { email: helpers.withMessage('The email is not valid', email) },
  lead_phone: {
    phone: {
      validatePhone: helpers.withMessage(PHONE_NUMBER_NOT_VALID, () =>
        validatePhone(localLead.value.lead_phone?.phone, localLead.value.lead_phone?.phone_country_id)
      ),
    },
    phone_country_id: {
      validatePhone: helpers.withMessage(PHONE_NUMBER_NOT_VALID, () =>
        validatePhone(localLead.value.lead_phone?.phone, localLead.value.lead_phone?.phone_country_id)
      ),
    },
  },
  ...(isCommunicationWayRepresentative.value
    ? {
        representative_phone: {
          phone: {
            validatePhone: helpers.withMessage(PHONE_NUMBER_NOT_VALID, () =>
              validatePhone(
                localLead.value.representative_phone?.phone,
                localLead.value.representative_phone?.phone_country_id
              )
            ),
          },
          phone_country_id: {
            validatePhone: helpers.withMessage(PHONE_NUMBER_NOT_VALID, () =>
              validatePhone(
                localLead.value.representative_phone?.phone,
                localLead.value.representative_phone?.phone_country_id
              )
            ),
          },
        },
      }
    : {}),
  ...(isCommunicationWayPartner.value
    ? {
        partner_phone: {
          phone: {
            validatePhone: helpers.withMessage(PHONE_NUMBER_NOT_VALID, () =>
              validatePhone(localLead.value.partner_phone?.phone, localLead.value.partner_phone?.phone_country_id)
            ),
          },
          phone_country_id: {
            validatePhone: helpers.withMessage(PHONE_NUMBER_NOT_VALID, () =>
              validatePhone(localLead.value.partner_phone?.phone, localLead.value.partner_phone?.phone_country_id)
            ),
          },
        },
      }
    : {}),
  country_id: { required: helpers.withMessage('Country is required', required) },
  language_id: { required: helpers.withMessage('Language is required', required) },
}))

const v$ = useVuelidate(rules, localLead)

const submit = async () => {
  const isValid = await v$.value.$validate()

  if (isValid) {
    saving.value = true

    const result = omitBy(localLead.value, (v) => v === null || v === undefined || v === 'hidden') as LocalLead

    const payload = {
      ...result,
      phone: result.lead_phone?.phone,
      phone_country_id: result.lead_phone?.phone_country_id,
      ...(result.representative?.phone
        ? {
            representative: {
              ...result.representative,
              phone: result.representative_phone.phone,
              phone_country_id: result.representative_phone.phone_country_id,
            },
          }
        : {}),
    } as Lead

    try {
      const leadUpdated = await useUpdateLead(props.leadId, payload)
      uiStore.showSnackBanner('Lead updated successfully')
      emits('input', leadUpdated)
      emits('update:modelValue', false)
    } catch (error: any) {
      uiStore.showSnackBanner(error.message, 'error')
    } finally {
      saving.value = false
    }
  }
}
</script>

<style scoped></style>
