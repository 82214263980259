import type { ApiResponse } from '~/types'
import type { FetchParameters } from '~/composables/utils/useAPIFetch'

export const useAPIPropertiesFetch = <T = ApiResponse>(path: FetchParameters[0], options?: FetchParameters[1]) => {
  const config = useRuntimeConfig()

  return useAPIFetch<T>(path, {
    baseURL: config.public.API_URL_PROPERTY_CATALOGUE,
    ...options,
  })
}
