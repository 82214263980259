<template>
  <div class="bg-bg-dark px-4 py-6 text-white md:px-6">
    <div class="mb-4 flex flex-col items-center justify-between gap-4 md:flex-row md:gap-0">
      <NuxtLink to="#">
        <CombinedLogo class="text-white" />
      </NuxtLink>
      <div class="mx-auto flex gap-4 md:pr-[64px]">
        <button
          class="rounded-full bg-dm-80 p-2.5"
          @click="goToLink('https://www.facebook.com/people/Thrivestate-Square/100087869512020/')"
        >
          <UiIcon name="facebook" />
        </button>
        <button class="rounded-full bg-dm-80 p-2.5" @click="goToLink('https://www.youtube.com/@thrivestatesquare')">
          <UiIcon name="youtube" />
        </button>
        <button
          class="rounded-full bg-dm-80 p-2.5"
          @click="goToLink('https://www.linkedin.com/company/thrive-state-square/')"
        >
          <UiIcon name="linkedin" />
        </button>
        <button class="rounded-full bg-dm-80 p-2.5" @click="goToLink('https://www.instagram.com/thrivestate_square/')">
          <UiIcon name="instagram" />
        </button>
      </div>
    </div>
    <hr class="text-dm-80" />
    <div class="mt-4 flex flex-col items-center justify-between gap-2 md:flex-row md:gap-0">
      <button
        class="text-body-2"
        @click="
          goToLink(
            'https://www.google.com/maps/place/ThriveState+Square/@25.0753976,55.1398252,17z/data=!3m1!4b1!4m6!3m5!1s0x3e5f6d104a7799a1:0x11f9a33341b357a1!8m2!3d25.0753976!4d55.1398252!16s%2Fg%2F11sf0h42b9?entry=ttu'
          )
        "
      >
        1303 Marina Plaza, Dubai Marina
      </button>
      <p class="text-body-2 flex items-center gap-2 text-dm-40">
        <UiIcon name="copyright" />{{ currentYear() }} ThriveState Square - Dubai
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import CombinedLogo from '@/assets/images/combined-logo.svg?component'
const phone = '971526942319'

const goToLink = (link: string) => {
  navigateTo(link, {
    external: true,
    open: {
      target: '_blank',
    },
  })
}

const currentYear = () => new Date().getFullYear()
</script>

<style scoped></style>
