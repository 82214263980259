<template>
  <UiPopup v-model="modelValue" title="call from" size="small">
    <div class="flex flex-col gap-3 pb-4">
      <UiButtonBase
        v-for="method in callMethods"
        :id="method.value"
        :key="method.value"
        class="w-full"
        type="secondary"
        @click="choose(method.value)"
      >
        <UiIcon name="call" />
        {{ method.text }}
      </UiButtonBase>
    </div>
  </UiPopup>
</template>

<script setup lang="ts">
import type { InputItem } from '~/types'

const emits = defineEmits(['input'])

const modelValue = defineModel<boolean>({ required: true })

type Props = {
  callMethods: InputItem[]
}

defineProps<Props>()

const choose = (value: string) => {
  emits('input', value)
  modelValue.value = false
}
</script>

<style scoped></style>
