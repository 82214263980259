import * as Sentry from '@sentry/vue'
import { stringify } from 'qs'
import type { APIFetchOptions } from '~/composables/utils/useAPIFetch'
import { useAuthStore } from '~/store/auth'
import { useUiStore } from '~/store/ui'

const logoutExceptions = ['/calendar']

/* eslint-disable no-console */
export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig()

  const uiStore = useUiStore()
  const authStore = useAuthStore()

  const getOptionsQuery = (query: APIFetchOptions['query'], stringifyOptions: APIFetchOptions['stringifyOptions']) => {
    const options: APIFetchOptions['stringifyOptions'] = {
      arrayFormat: 'indices',
      encode: true,
      ...stringifyOptions,
    }

    const convertedOptions = new URLSearchParams(stringify(query, options))

    return Object.fromEntries(convertedOptions)
  }

  const api = $fetch.create({
    baseURL: runtimeConfig.public.API_URL,
    retry: 0,
    onRequest({ options: requestOptions }) {
      const options = requestOptions as APIFetchOptions

      const token = authStore.getToken

      if (token) {
        const headers = (options.headers ||= {})
        if (Array.isArray(headers)) {
          headers.push(['Authorization', `Bearer ${token}`])
          headers.push(['Accept', 'application/json'])
        } else if (headers instanceof Headers) {
          headers.set('Authorization', `Bearer ${token}`)
          headers.set('Accept', 'application/json')
        } else {
          headers.Authorization = `Bearer ${token}`
          headers.Accept = 'application/json'
        }
      }

      options.query = getOptionsQuery(options.query, options.stringifyOptions)

      if (options.params) {
        const convertedOptions = new URLSearchParams(stringify(options.params))
        options.params = Object.fromEntries(convertedOptions)
      }
    },
    onResponseError({ request, response }) {
      switch (response.status) {
        case 401:
          if (!logoutExceptions.some((e) => request.toString().includes(e))) {
            useLogout(false)
          }
          break
        case 422:
          // Don't show snack bar with validation errors
          break
        case 404:
          throw showError({
            message: response._data.message,
            data: response._data,
            statusCode: response.status,
          })
        case 400:
          throw showError({
            message: response._data.message,
            data: response._data,
            statusCode: response.status,
          })
        default:
          Sentry.captureMessage(response?._data?.message, 'error')

          uiStore.showSnackBanner(response._data.message, 'error')
      }

      throw createError({
        message: response._data.message,
        data: response._data,
        statusCode: response.status,
      })
    },
  })

  return {
    provide: {
      api,
    },
  }
})
