<template>
  <div class="flex min-h-14 flex-col gap-2">
    <div class="text-caption flex items-center gap-1 text-black-90">
      <UiTimer :end-date="paymentToken.expires_at" @ended="emits('ended', paymentToken.id)" />
      <span>{{ $t('on_hold') }}</span>

      <span>•</span>

      <span>{{ paymentToken.type.name }}</span>

      <div
        v-if="closable"
        class="ml-auto cursor-pointer rounded-lg transition-colors hover:bg-primary-05"
        @click="emits('close')"
      >
        <UiIcon name="small-close" class="text-primary-100" />
      </div>
    </div>
    <I18n-t keypath="reservation_lead_description" tag="div" class="text-body-2">
      <template #unit>
        <span class="text-subhead-4">{{ paymentToken.unit.unit_number }}</span>
      </template>
    </I18n-t>
    <UiButtonBase v-if="paymentToken.type.code === PAYMENT_TOKEN_CODES.MANUAL" id="add_confirmation" size="small">
      {{ $t('add_confirmation') }}
    </UiButtonBase>
  </div>
</template>

<script setup lang="ts">
import type { UnitPaymentToken } from '~/types/properties'
import { PAYMENT_TOKEN_CODES } from '~/constants'

defineProps<{
  paymentToken: UnitPaymentToken
  closable?: boolean
}>()

const emits = defineEmits<{
  (e: 'close'): void
  (e: 'ended', id: UnitPaymentToken['id']): void
}>()
</script>

<style scoped></style>
