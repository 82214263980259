<template>
  <div class="text-body-2 flex flex-col gap-2">
    <div>
      <span class="text-caption mr-2 rounded-lg bg-additional-1-50 p-1 text-error-100">{{ $t('on_hold') }}</span>
      <span class="text-body-2">
        <NuxtLink
          v-if="unitNumber"
          :to="`/properties/exclusives?query=${unitNumber}&project_ids=${projectId}`"
          target="_blank"
          class="text-subhead-4 underline"
        >
          {{ unitNumber }}
        </NuxtLink>
        <span v-else class="text-subhead-4 inline-block capitalize">{{ $t('unit') }}</span>

        has been reserved by
        <span class="text-subhead-4"> {{ reservedBy }} </span>.
      </span>
    </div>
    <div class="flex flex-col gap-1">
      <template v-if="isOtherPaymentMethod">
        <span class="text-caption text-black-40">Payment method:</span>
        <p class="text-body-2">
          <span class="text-subhead-4 text-primary-100">Add payment confirmation</span> documents on a Tokens tab
        </p>
      </template>
      <template v-else>
        <span class="text-caption text-black-40">Payment link:</span>
        <p class="text-body-2">The payment link and token information are stored on the Tokens tab.</p>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { TimelineItem } from '@/types'
import { PAYMENT_TOKEN_CODES } from '~/constants'

type Props = {
  modelValue: TimelineItem
}
const props = defineProps<Props>()

const reservedBy = computed(() => props.modelValue.additional?.find((i) => i.field === 'Agent')?.value)

const unitNumber = computed(() => props.modelValue.additional?.find((i) => i.field === 'Unit number')?.value)
const projectId = computed(() => props.modelValue.additional?.find((i) => i.field === 'Project id')?.value)

const isOtherPaymentMethod = computed(
  () => props.modelValue.additional?.find((i) => i.field === 'Token type')?.value === PAYMENT_TOKEN_CODES.MANUAL
)
</script>

<style scoped lang="scss"></style>
