import { H3Error } from 'h3'
import type { stringify } from 'qs'
import type { ApiResponse } from '~/types'

type StringifyType = typeof stringify

export type StringifyParameters = Parameters<StringifyType>

type FetchType = typeof $fetch

export type FetchParameters = Parameters<FetchType>

export type APIFetchOptions = FetchParameters[1] & {
  stringifyOptions?: StringifyParameters[1]
}

export const useAPIFetch = async <T = ApiResponse>(path: FetchParameters[0], options?: APIFetchOptions) => {
  const { $api } = useNuxtApp()

  const data = ref<T>()

  const error = ref<H3Error | undefined>(undefined)

  try {
    const response = await $api<T>(path, options)

    data.value = response as T
  } catch (err: any) {
    error.value = err
  }

  return {
    data: data as Ref<T>,
    error,
  }
}
