<template>
  <UiPopup
    v-model="modelValue"
    size="extraBig"
    align-title-left
    popup-classes="max-h-[600px] h-full"
    @update:model-value="modelValue = false"
  >
    <div class="absolute top-10 z-[1] flex w-[calc(100%-102px)] flex-row items-center justify-between">
      <h4>{{ currentStep.title }}</h4>
      <span class="text-caption-2 text-black-70">Step {{ currentStep.id }}/{{ steps.length }}</span>
    </div>
    <Transition name="fade" mode="out-in">
      <keep-alive>
        <component
          :is="currentStep.component"
          v-model="data"
          :rule-to-edit="ruleToEdit"
          @cancel="modelValue = false"
          @next="nextStep"
          @back="previousStep"
          @created="submit"
        />
      </keep-alive>
    </Transition>
  </UiPopup>
</template>

<script setup lang="ts">
import cloneDeep from 'lodash/cloneDeep'
import type { Mapping, MappingRule } from '~/types'

const EDIT_MAPPING_TEXT = 'Edit mapping rule'
const CREATE_MAPPING_TEXT = 'Create mapping rule'

const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  ruleToEdit?: Mapping
}

const props = withDefaults(defineProps<Props>(), {
  ruleToEdit: undefined,
})

const modelValue = defineModel<boolean>({ required: true })

const data = ref<Partial<Mapping>>(
  props.ruleToEdit
    ? cloneDeep({
        ...props.ruleToEdit,
        rotation_id: props.ruleToEdit.rotation?.type === 'Pool' ? undefined : props.ruleToEdit.rotation?.id,
        language_id: props.ruleToEdit?.language?.id,
        user_id: props.ruleToEdit?.user?.id,
        rulesOr: props.ruleToEdit.rules?.filter((r: MappingRule) => r.union_operator === 'or') || [],
        rules: props.ruleToEdit.rules?.filter((r: MappingRule) => r.union_operator === 'and') || [],
      })
    : {
        name: '',
        rules: [],
        rulesOr: [],
        rotation_id: undefined,
        language_id: undefined,
        user_id: undefined,
        call_queue: false,
        call_queue_handler_id: undefined,
      }
)

const stepTitle = computed(() => (props.ruleToEdit ? EDIT_MAPPING_TEXT : CREATE_MAPPING_TEXT))

const steps = ref([
  {
    id: 1,
    title: stepTitle.value,
    component: shallowRef(resolveComponent('RotationMappingCreateEditForm')),
  },
  {
    id: 2,
    title: stepTitle.value,
    component: shallowRef(resolveComponent('RotationMappingCreateEditFormQueue')),
  },
  {
    id: 3,
    title: stepTitle.value,
    component: shallowRef(resolveComponent('RotationMappingCreateEditFormRotation')),
  },
])
const currentStep = ref(steps.value[0])

const nextStep = () => {
  const nextStepIndex = steps.value.findIndex((step) => step.id === currentStep.value.id) + 1
  currentStep.value = steps.value[nextStepIndex]
}

const previousStep = () => {
  const previousStepIndex = steps.value.findIndex((step) => step.id === currentStep.value.id) - 1
  currentStep.value = steps.value[previousStepIndex]
}

const submit = () => {
  emits('input')
  modelValue.value = false
}
</script>

<style scoped></style>
