<template>
  <div class="flex items-center overflow-hidden rounded-3xl">
    <div class="flex w-1/2 items-center gap-6 bg-primary-10 px-6 py-4">
      <ActivityIllustration />
      <div>
        <h5 class="mb-3">Follow ups</h5>
        <p class="text-body-2 mb-6">Set up activity to keep lead on track.</p>
        <UiButtonBase id="activity" :disabled="isClosed" type="secondary" @click="emits('create-activity')">
          <UiIcon name="calendar" />
          Activity
        </UiButtonBase>
      </div>
    </div>
    <div class="flex w-1/2 items-center gap-6 bg-white px-6 py-4">
      <ContactIllustration />
      <div>
        <h5 class="mb-3">Contact lead</h5>
        <p class="text-body-2 mb-6">Contact with the lead using the best channel.</p>
        <div class="flex gap-3">
          <LeadCall
            v-model="modelValue"
            :disabled="isClosedLost || isUnverifiedLead"
            :update-lead="isSdrQueue"
            @called="$emit('called', $event)"
            @call-started="$emit('call-started')"
          />
          <UiButtonBase
            v-if="useGetShowPhoneLead(modelValue)"
            id="whatsapp_message"
            type="secondary"
            :disabled="isUnverifiedLead || isClosed"
            @click="emits('send-whatsapp-message')"
          >
            <UiIcon name="whatsapp" :class="isUnverifiedLead || isClosed ? 'text-disabled-40' : 'text-whatsapp'" />
            WhatsApp
          </UiButtonBase>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ActivityIllustration from '@/assets/illustrations/activity.svg?component'
import ContactIllustration from '@/assets/illustrations/contact.svg?component'
import type { Lead } from '@/types'

type Props = {
  isSdrQueue?: boolean
  isUnverifiedLead?: boolean
}

defineProps<Props>()

const emits = defineEmits(['create-activity', 'send-whatsapp-message', 'called', 'call-started'])
const isClosed = inject<Ref<boolean>>('isClosed')
const isClosedLost = inject<Ref<boolean>>('isClosedLost')

const modelValue = defineModel<Lead>()
</script>

<style scoped></style>
