import type { AgentBlacklist, PaginationResponse, LandingTemplate } from '@/types'

export const useGetSettingsLeads = async () => {
  const { data, error } = await useAPIFetch('/settings/leads')
  if (error.value) throw createError(error.value)
  return (data.value as any)?.data
}
export const usePostSettingsLeads = async (body: Record<string, any>) => {
  const { data, error } = await useAPIFetch('/settings/leads/update', { method: 'POST', body })
  if (error.value) throw createError(error.value)
  return (data.value as any)?.data
}

export const useGetSettingsByCode = async <T extends any>(code: string) => {
  const { data, error } = await useAPIFetch(`/settings/setting/${code}`)
  if (error.value) throw createError(error.value)
  return (data.value as any)?.data as T
}

export const useGetSettingGroupByCodes = async <T extends any>(query: { code: string[] }) => {
  const { data, error } = await useAPIFetch(`/settings/group`, {
    query,
  })
  if (error.value) throw createError(error.value)
  return (data.value as any)?.data as T
}

export const useUpdateSettingsByCode = async (code: string, body: object) => {
  const { data, error } = await useAPIFetch(`/settings/setting/${code}`, { method: 'PATCH', body })
  if (error.value) throw createError(error.value)
  return data.value as any
}

export const useGetAgentsBlacklists = async (query: Record<string, any>) => {
  const { data, error } = await useAPIFetch<PaginationResponse>(`/settings/blacklist`, {
    query,
    stringifyOptions: { arrayFormat: 'comma' },
  })
  if (error.value) throw createError(error.value)

  return toRaw(data.value)
}

export const useCreateAgentBlacklist = async (agentBlacklist: AgentBlacklist) => {
  const { data, error } = await useAPIFetch<AgentBlacklist>(`/settings/blacklist`, {
    method: 'POST',
    body: agentBlacklist,
  })
  if (error.value) throw createError(error.value)

  return toRaw(data.value)
}

export const useUpdateAgentBlacklist = async (agentBlacklist: AgentBlacklist) => {
  const { data, error } = await useAPIFetch<AgentBlacklist>(`/settings/blacklist/${agentBlacklist.id}`, {
    method: 'PUT',
    body: agentBlacklist,
  })
  if (error.value) throw createError(error.value)

  return toRaw(data.value)
}

export const useDeleteAgentBlacklist = async (userId: number) => {
  const { data, error } = await useAPIFetch(`/settings/blacklist/${userId}`, { method: 'DELETE' })
  if (error.value) throw createError(error.value)
  return data.value
}

export const useGetNotificationTemplates = async (query: Record<string, any>) => {
  const { data, error } = await useAPIFetch<PaginationResponse>(`/templates`, { query })
  if (error.value) throw createError(error.value)

  return data.value?.data
}

export const useCreateNotificationTemplate = async (body: Object) => {
  const { data, error } = await useAPIFetch(`/templates`, { method: 'POST', body })
  if (error.value) throw createError(error.value)

  return data.value
}

export const useAddTemplateLanguage = async (Id: number, body: Object) => {
  const { data, error } = await useAPIFetch(`/templates/${Id}`, { method: 'PATCH', body })
  if (error.value) throw createError(error.value)

  return data.value
}

export const useRemoveTemplateLanguage = async (id: number, translationId: number) => {
  const { data, error } = await useAPIFetch(`/templates/${id}/translations/${translationId}`, { method: 'DELETE' })
  if (error.value) throw createError(error.value)

  return data.value
}

export const useGetTemplateList = async () => {
  const { data, error } = await useAPIFetch(`/templates/dropdown`)
  if (error.value) throw createError(error.value)
  return (data.value as any)?.data
}

export const useGetTemplateSettingsByCode = async <T extends any>(code: string) => {
  const { data, error } = await useAPIFetch(`/settings/templates/${code}`)
  if (error.value) throw createError(error.value)
  return (data.value as any)?.data as T
}

export const useUpdateTemplateSettingsByCode = async (code: string, body: object) => {
  const { data, error } = await useAPIFetch(`/settings/templates/${code}`, { method: 'PATCH', body })
  if (error.value) throw createError(error.value)
  return data.value as any
}

export const useGetLandingTranslations = async () => {
  const { data, error } = await useAPIFetch('/landing-widget/translations')
  if (error.value) throw createError(error.value)
  return data.value as any
}

export const useLandingTranslations = async (body: LandingTemplate) => {
  const { data, error } = await useAPIFetch('/landing-widget/translations', { method: 'post', body })
  if (error.value) throw createError(error.value)
  return data.value as any
}

export const useGetLandingImage = async () => {
  const { data, error } = await useAPIFetch('/landing-widget/image')
  if (error.value) throw createError(error.value)
  return data.value as any
}

export const useLandingImage = async (body: FormData) => {
  const { data, error } = await useAPIFetch('/landing-widget/image', { method: 'post', body })
  if (error.value) throw createError(error.value)
  return data.value as any
}
