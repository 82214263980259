<template>
  <UiPopup
    :model-value="modelValue"
    hide-close-button
    title="Remove tag?"
    :description="`Are you sure you want to remove ${tag} tag?`"
    primary-button-text="Confirm"
    secondary-button-text="Cancel"
    size="small"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="confirm"
  />
</template>

<script setup lang="ts">
const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
  tag: string
}

withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const confirm = () => {
  emits('input')
  emits('update:modelValue', false)
}
</script>

<style scoped></style>
