<template>
  <div class="h-0 max-h-[950px] min-h-[740px] w-full pt-[55.72%]">
    <div class="absolute top-0 flex size-full">
      <div class="flex w-1/2 flex-col items-start bg-black-03 p-8">
        <div class="text-headline-4 mb-8">{{ $t('generate_payment_link') }}</div>
        <div class="flex w-full flex-col items-start gap-2">
          <div class="text-subhead-2">{{ $t('you_are_going_to_reserve') }}</div>
          <div class="mb-6 flex w-full gap-3 rounded-xl bg-white p-4">
            <img
              :src="property?.photo"
              alt="image"
              class="h-full max-h-[116px] w-2/5 rounded-lg bg-black-30 object-cover"
            />
            <div class="flex w-3/5 flex-col justify-start gap-1">
              <div class="flex items-center justify-between">
                <div class="text-caption text-black-40">{{ $t('project') }}</div>
                <div class="text-body-2">{{ unit?.property_name }}</div>
              </div>
              <div class="flex items-center justify-between">
                <div class="text-caption text-black-40">{{ $t('developer') }}</div>
                <div class="text-body-2">{{ unit?.developer_name }}</div>
              </div>
              <div class="flex items-center justify-between">
                <div class="text-caption text-black-40">{{ $t('unit_number') }}</div>
                <div class="text-body-2">{{ unit?.unit_number }}</div>
              </div>
              <div class="flex items-center justify-between">
                <div class="text-caption text-black-40">{{ $t('token') }}</div>
                <div class="text-subhead-3">{{ linkData.amount }}</div>
              </div>
            </div>
          </div>

          <div class="text-subhead-2">{{ $t('for_user') }}:</div>
          <div class="mb-6 flex w-full flex-col justify-start gap-1 rounded-xl bg-white p-4">
            <div class="flex items-center justify-between">
              <div class="text-caption text-black-40">{{ $t('lead_name') }}</div>
              <div class="text-body-2">{{ linkData.lead.name }}</div>
            </div>
            <div class="flex items-center justify-between">
              <div class="text-caption text-black-40">{{ $t('phone') }}</div>
              <div class="text-body-2">
                {{ `+${getPhoneCode(linkData.lead.phone_country_id)} ${linkData.lead.phone?.toString()}` }}
              </div>
            </div>
            <div class="flex items-center justify-between">
              <div class="text-caption text-black-40">{{ $t('email') }}</div>
              <div class="text-body-2">{{ linkData.lead.email }}</div>
            </div>
          </div>

          <div class="text-subhead-2">{{ $t('payment_method') }}:</div>
          <div class="mb-6 flex w-full flex-col justify-start gap-1 rounded-xl bg-white p-4 text-start">
            <div class="text-subhead-3">{{ $t('payment_link') }}</div>
            <div class="text-body-2">{{ $t('link_will_expire', { hours: holdingHours }) }}</div>
          </div>
        </div>
      </div>
      <div class="flex h-full w-1/2 flex-col justify-between p-8">
        <div class="flex h-full flex-col items-start">
          <div class="mb-[58px] flex w-full items-center justify-end text-black-70">
            <div class="text-caption-2 mr-4">{{ $t('step') }} 2/2</div>
            <button @click="emits('close')">
              <UiIcon name="big-close"></UiIcon>
            </button>
          </div>
          <div class="flex size-full flex-col justify-between">
            <div class="flex w-full flex-col items-center justify-center">
              <SocialIllustration class="mb-10" />
              <div
                v-if="linkData.type.code !== PAYMENT_TOKEN_CODES.MANUAL"
                class="mx-2 flex items-center gap-4 rounded-lg bg-primary-05 p-3"
              >
                <UiButtonBase id="copy" type="secondary" size="small" compact @click="copyLink">
                  <div class="flex items-center">
                    <UiIcon name="union" />
                    {{ $t('copy') }}
                  </div>
                </UiButtonBase>
                <div class="text-body-2">{{ $t('link_for_payment_text') }}</div>
              </div>

              <div v-else class="flex w-full max-w-[400px] flex-col items-center justify-center">
                <div class="text-body-2 mb-4">{{ $t('other_payment_method_text1', { hours: holdingHours }) }}</div>
                <div class="text-subhead-3">{{ $t('other_payment_method_text2') }}</div>
              </div>
              <div
                v-if="showCopiedMessage"
                class="text-subhead-2 mx-2 w-[calc(100%_-_1rem)] max-w-[460px] rounded-lg bg-dm-90 px-4 py-1 text-start text-white"
              >
                {{ $t('link_is_copied') }}
              </div>
            </div>
            <div class="flex w-full items-center justify-center">
              <UiButtonBase id="next" @click="openLeadPage">
                {{ $t('open_lead_page') }}
              </UiButtonBase>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SocialIllustration from '@/assets/illustrations/social.svg?component'
import type { ExclusiveUnit } from '~/types/properties'
import type { InputItemCountry, PropertyTypes } from '@/types'
import { useUiStore } from '~/store/ui'
import { PAYMENT_TOKEN_CODES } from '~/constants'

type Props = {
  linkData: any
  unit: ExclusiveUnit | null
  holdingHours: string
}

const props = defineProps<Props>()

const emits = defineEmits<{
  (event: 'close'): void
  (event: 'nextStep'): void
}>()

const property = ref<PropertyTypes.PropertyDetailed | null>(null)

const uiStore = useUiStore()

const countries = ref<InputItemCountry[]>([])
const showCopiedMessage = ref<boolean>(false)

onNuxtReady(async () => await getCountries())

const getCountries = async () => {
  try {
    const countriesList = await useCountries()
    countries.value = countriesList.map((c) => ({ text: c.name, value: c.id, ...c }))
  } catch (error: any) {
    uiStore.showSnackBanner('Failed to fetch countries list, please try again later', 'error')
  }
}

const getPhoneCode = (countryId?: number) => {
  return countries.value.find((el) => el.id === countryId)?.phone_code
}

const openLeadPage = () => {
  navigateTo(`/leads/${props.linkData.lead.id}`)
}

const copyLink = () => {
  if (props.linkData.hash) {
    showCopiedMessage.value = true
    navigator.clipboard.writeText(props.linkData.hash as string)
    setTimeout(() => {
      showCopiedMessage.value = false
    }, 3000)
  }
}

onMounted(async () => {
  try {
    const { data } = await useGetProperty(`${props.unit?.property_id}`)

    property.value = data.value.data
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
})
</script>

<style scoped></style>
