<template>
  <span class="min-h-6 rounded-lg bg-primary-05 px-2 py-1 text-primary-90">
    {{ timeLeft }}
  </span>
</template>

<script setup lang="ts">
import { differenceInSeconds, isBefore, parseISO } from 'date-fns'

const props = defineProps<{
  endDate: string
}>()

const emit = defineEmits<{
  (e: 'ended'): void
}>()

const DEFAULT_TIME_LEFT = '00:00:00'

const timer = ref<NodeJS.Timeout | null>(null)
const timeLeft = ref(DEFAULT_TIME_LEFT)

const clearTimer = () => {
  timeLeft.value = DEFAULT_TIME_LEFT

  timer.value && clearInterval(timer.value)
  timer.value = null
}

const startCountdown = (end: string) => {
  const endDate = parseISO(end)

  const updateTimer = () => {
    const now = new Date()

    if (isBefore(now, endDate)) {
      const difference = differenceInSeconds(endDate, now)

      const hours = Math.floor(difference / 3600)
      const minutes = Math.floor((difference - hours * 3600) / 60)
      const seconds = difference - hours * 3600 - minutes * 60

      const hoursPadded = hours.toString().padStart(2, '0')
      const minutesPadded = minutes.toString().padStart(2, '0')
      const secondsPadded = seconds.toString().padStart(2, '0')

      timeLeft.value = `${hoursPadded}:${minutesPadded}:${secondsPadded}`
    } else {
      timeLeft.value = DEFAULT_TIME_LEFT

      emit('ended')

      clearTimer()
    }
  }

  timer.value = setInterval(updateTimer, 1000)

  updateTimer()
}

onMounted(() => {
  startCountdown(props.endDate)
})

onUnmounted(() => {
  clearTimer()
})
</script>

<style scoped></style>
