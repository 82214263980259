<template>
  <div :id="name" :ref="(el) => (refs[name].value = el as HTMLInputElement)">
    <slot name="activator" :is-open="showMenu" @click="toggle" @remove="emits('remove')" />

    <Teleport to="body">
      <transition name="fade">
        <div
          v-if="showMenu"
          :id="`${name}_custom_menu`"
          :ref="(el) => (refs[`${name}_custom_menu`].value = el as HTMLInputElement)"
          class="fixed z-50 rounded-xl border-[1.5px] border-solid border-primary-10 bg-white shadow"
          :class="menuClasses"
        >
          <slot name="content" />
        </div>
      </transition>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import debounce from 'lodash/debounce'
import { onClickOutside, type OnClickOutsideOptions } from '@vueuse/core'

const emits = defineEmits(['update:modelValue', 'closed', 'remove'])

type Props = {
  name: string
  width?: CSSStyleDeclaration['width']
  alignRight?: boolean
  alignCenter?: boolean
  menuClasses?: string
  positionTop?: boolean
  ignore?: OnClickOutsideOptions['ignore']
}
const props = withDefaults(defineProps<Props>(), {
  width: undefined,
  menuClasses: '',
  ignore: undefined,
})

const showMenu = ref<boolean>(false)

const refs = {
  [props.name]: ref<HTMLInputElement>(),
  [`${props.name}_custom_menu`]: ref<HTMLElement>(),
}

const calculatePosition = () => {
  if (!showMenu.value) return
  const viewportOffset = refs[props.name].value?.getBoundingClientRect()

  if (!viewportOffset) return
  // Here we get the element position to the viewport
  const top = viewportOffset.top
  const left = viewportOffset.left

  //   Then we set the position of the menu to those coordinates (if theres space)
  //   We do this because we use Teleport to transport to the body of the page
  //   Because otherwise, the menu would be clipped by tables, forms, etc
  //   Source: Trust me
  nextTick(() => {
    const menu = refs[`${props.name}_custom_menu`].value

    if (menu) {
      const spaceToBottom = window.innerHeight - top
      if (spaceToBottom < menu.offsetHeight + Number(refs[props.name].value?.offsetHeight || props.positionTop)) {
        menu.style.top = `${top - menu.offsetHeight + 2}px`
      } else {
        menu.style.top = `${top + Number(refs[props.name].value?.offsetHeight) + 2}px`
      }
      const spaceToRight = window.innerWidth - left

      const width = Number(props.width || refs[props.name].value?.offsetWidth)
      if (props.positionTop) {
        menu.style.left = `${left + (Number(refs[props.name].value?.offsetWidth) - width) / 2}px`
      } else if (spaceToRight > width / 2 && props.alignCenter) {
        const leftPosition = left + (Number(refs[props.name].value?.offsetWidth) - width) / 2
        menu.style.left = `${leftPosition}px`
      } else if (spaceToRight < width || props.alignRight) {
        menu.style.left = `${left - width + Number(refs[props.name].value?.offsetWidth)}px`
      } else {
        menu.style.left = `${left}px`
      }

      menu.style.width = `${width}px`
    }
  })
}

const toggle = debounce(() => {
  showMenu.value = !showMenu.value
  calculatePosition()
})

const closeMenu = () => {
  if (showMenu.value) showMenu.value = false
}

const openMenu = () => {
  if (!showMenu.value) showMenu.value = true
  calculatePosition()
}

onMounted(() => {
  const menu = refs[`${props.name}_custom_menu`]
  onClickOutside(
    menu,
    (event) => {
      // get the element that was clicked
      const target = document.elementFromPoint(event.x, event.y)
      // get the closest target element that's a menu
      const menuElement = target?.closest('[id*="menu"]')
      if (menuElement && menu.value) {
        // if menu element position start within this custom menu, then we assume the click was inside this custom menu
        if (!menuStartsInside(menu.value, menuElement as HTMLElement)) {
          setTimeout(() => {
            if (showMenu.value) showMenu.value = false
            emits('closed')
          }, 100)
        }
      } else {
        setTimeout(() => {
          if (showMenu.value) showMenu.value = false
          emits('closed')
        }, 100)
      }
    },
    { ignore: props.ignore }
  )
  window.addEventListener('scroll', calculatePosition, true)
})

const menuStartsInside = (originElement: HTMLElement, newElement: HTMLElement) => {
  if (!originElement || !newElement) return false
  return (
    newElement.getBoundingClientRect().top >= originElement.getBoundingClientRect().top &&
    newElement.getBoundingClientRect().top <= originElement.getBoundingClientRect().bottom &&
    newElement.getBoundingClientRect().left >= originElement.getBoundingClientRect().left &&
    newElement.getBoundingClientRect().left <= originElement.getBoundingClientRect().right
  )
}

onUnmounted(() => window.removeEventListener('scroll', calculatePosition))

defineExpose({
  closeMenu,
  openMenu,
})
</script>

<style scoped></style>
