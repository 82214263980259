<template>
  <UiPopup
    :model-value="modelValue"
    primary-button-text="Create landing template"
    secondary-button-text="Cancel"
    size="medium"
    compact
    @update:model-value="emits('update:modelValue', false)"
    @confirm="submit"
  >
    <template #title><h4>Create language template for landing widget</h4></template>
    <form class="mt-4" @submit.prevent>
      <p class="text-subhead-2 mb-2 text-left">Language for templates</p>
      <UiInputSelect
        v-model="data.language_id"
        :items="languageItems"
        name="Languages"
        avatar
        compact
        placeholder="Select language"
        class="mb-8"
        :error="useGetFieldErrors(v$, ['language_id'])"
      />
      <div class="flex flex-col gap-6">
        <div v-for="(item, index) in data.fields" :key="index" class="rounded-lg bg-black-03 p-3">
          <UiInputTextarea
            v-model="item.value"
            :name="`template-${item.key}`"
            placeholder="Add template text"
            :label="templateMap[item.key]"
            text-area-class="!min-h-[20px] max-h-[100px]"
            :max-length="150"
            class="[&>label]:bg-white"
            without-transition
            compact
            :error="useGetFieldErrorsByIndex(v$, 'fields', 'value', index)"
          />
        </div>
      </div>
    </form>
  </UiPopup>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import { useUiStore } from '~/store/ui'
import type { InputItem, LandingTemplate } from '@/types'

const uiStore = useUiStore()

const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
  languageItems: InputItem[]
}

withDefaults(defineProps<Props>(), {
  modelValue: false,
  languageItems: () => [],
})

const templateMap: Record<string, string> = {
  redirection_text: 'Text for redirection from widget',
  autoreply_text: 'Text for auto-reply',
  greeting_text: 'Text for widget title',
  description_text: 'Text for widget description',
}

const data = ref<LandingTemplate>({
  language_id: null,
  fields: [
    {
      key: 'redirection_text',
      value: '',
    },
    {
      key: 'autoreply_text',
      value: '',
    },
    {
      key: 'greeting_text',
      value: '',
    },
    {
      key: 'description_text',
      value: '',
    },
  ],
})

const rules = computed(() => ({
  language_id: {
    required: helpers.withMessage(' ', required),
  },
  fields: {
    $each: helpers.forEach({
      value: {
        required: helpers.withMessage(' ', required),
      },
    }),
  },
}))

const v$ = useVuelidate(rules, data, { $stopPropagation: true })

const submit = async () => {
  const isValid = await v$.value.$validate()
  if (!isValid) return
  try {
    await useLandingTranslations(data.value)
    emits('input')
    emits('update:modelValue', false)
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}
</script>
