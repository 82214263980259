import type { InputItem } from '~/types'
import { useLibrariesStore } from '~/store/libraries'
import { useUiStore } from '~/store/ui'

export default (value: string) => {
  const uiStore = useUiStore()
  onNuxtReady(async () => {
    try {
      await useGetPropertyProjects()
    } catch (error: any) {
      uiStore.showSnackBanner(error.message, 'error')
    }
  })
  const librariesStore = useLibrariesStore()
  const project = librariesStore.getPropertyProjects.find((item: InputItem) => item.id === value)?.name
  return project
}
