<template>
  <div class="h-0 max-h-[950px] min-h-[740px] w-full pt-[55.72%]">
    <div class="absolute top-0 flex size-full">
      <div class="flex h-full w-1/2 flex-col items-start bg-black-03 p-8">
        <div class="text-headline-4 mb-8">{{ $t('generate_payment_link') }}</div>
        <div class="mb-6 flex w-full flex-col items-start gap-3">
          <span class="text-subhead-2">{{ $t('token_amount') }}:</span>
          <UiInputTextField
            v-model="paymentLinkData.amount"
            name="amount"
            placeholder="Token amount"
            type="number"
            compact
            :error="useGetFieldErrors(v$, ['amount'])"
            :disabled="showAddLeadForm"
          />
        </div>
        <div class="flex max-h-[calc(100%_-_130px)] w-full flex-col items-start gap-3">
          <span class="text-subhead-2">{{ $t('lead_name') }}:</span>
          <UiInputSelect
            ref="leadSelect"
            v-model="paymentLinkData.lead_id"
            :items="leads"
            name="lead"
            placeholder="Select lead"
            compact
            async
            :loading="retrieving"
            :disabled="showAddLeadForm"
            :error="useGetFieldErrors(v$, ['lead_id'])"
            @updated:shallow-value="getActiveLeads"
          >
            <template #simpleItem="item">
              <div class="flex w-full flex-col gap-1">
                <div class="flex w-full items-center justify-between">
                  <div class="text-body-2 w-1/2">{{ item.item?.name }}</div>
                  <div class="text-caption-2 flex w-1/2 items-center justify-end">
                    {{ format(new Date(item.item?.updated_at), 'MMM dd, hh:mm aa') }}
                    <UiTooltip tooltip-classes="z-[100]" name="stats">
                      <template #activator>
                        <UiIcon name="clock" size="xxs" class="ml-1"></UiIcon>
                      </template>
                      <template #content>
                        {{ $t('lead_last_update_time') }}
                      </template>
                    </UiTooltip>
                  </div>
                </div>
                <div class="flex w-full items-center justify-between">
                  <div class="text-caption w-1/2">
                    <div class="flex w-max items-center rounded-md bg-additional-2-20 px-2 py-1">
                      <UiIcon name="status-to_do" size="xxs" class="mr-1"></UiIcon>{{ item.item?.stage?.name }}
                    </div>
                  </div>
                  <div class="text-caption-2 flex w-1/2 items-center justify-end">
                    {{ item.item?.assignee?.name }} <UiIcon name="user" size="xxs" class="ml-1"></UiIcon>
                  </div>
                </div>
              </div>
            </template>
            <template #noOptions>
              <div
                v-if="!showAddLeadForm"
                class="text-subhead-1 flex cursor-pointer items-center text-primary-100"
                @click="clickAddLeadForm"
              >
                <UiIcon name="big-add-circle" class="mr-2"></UiIcon> {{ $t('add_new_user') }}
              </div>
            </template>
          </UiInputSelect>
          <div
            v-if="showAddLeadForm"
            class="size-full overflow-y-auto rounded-xl border border-primary-30 bg-white p-4 shadow-lg"
          >
            <PropertiesExclusivesGeneratePaymentLinkCreateLeadForm
              @created="addedNewLead"
              @cancel="showAddLeadForm = false"
            />
          </div>
        </div>
      </div>
      <div class="flex w-1/2 flex-col justify-between p-8">
        <div class="flex flex-col items-start">
          <div class="mb-7 flex w-full items-center justify-end text-black-70">
            <div class="text-caption-2 mr-4">{{ $t('step') }} 1/2</div>
            <button @click="emits('close')">
              <UiIcon name="big-close"></UiIcon>
            </button>
          </div>
          <div class="text-subhead-2 mb-3 text-black-90">{{ $t('payment_method') }}</div>
          <div
            v-if="paymentTokenTypes.length"
            class="mb-3 w-full rounded-lg border border-black-70 p-3"
            :class="{ 'border-error-100': useGetFieldErrors(v$, ['type']) }"
          >
            <UiInputRadio
              id="paymentLink"
              v-model="paymentLinkData.type"
              name="Payment link"
              :items="[paymentTokenTypes?.find((t) => t.code === PAYMENT_TOKEN_CODES.LINK)!]"
              input-class="min-w-5"
              label-class=" flex flex-col items-start"
              full-width
              round
              @update:model-value="
                paymentLinkData.type = paymentTokenTypes?.find((t) => t.code === PAYMENT_TOKEN_CODES.LINK)!.value
              "
            >
              <template #label>
                <div class="text-subhead-4 mb-2 w-full text-start text-black-100">{{ $t('payment_link') }}</div>
                <div class="text-body-2 text-start text-black-100">{{ $t('payment_link_text') }}</div>
              </template>
            </UiInputRadio>
          </div>
          <div
            v-if="paymentTokenTypes.length"
            class="w-full rounded-lg border border-black-70 p-3"
            :class="{ 'border-error-100': useGetFieldErrors(v$, ['type']) }"
          >
            <UiInputRadio
              id="other"
              v-model="paymentLinkData.type"
              name="Payment link"
              :items="[paymentTokenTypes?.find((t) => t.code === PAYMENT_TOKEN_CODES.MANUAL)!]"
              input-class="min-w-5"
              label-class=" flex flex-col items-start"
              full-width
              round
              @update:model-value="
                paymentLinkData.type = paymentTokenTypes?.find((t) => t.code === PAYMENT_TOKEN_CODES.MANUAL)!.value
              "
            >
              <template #label>
                <div class="text-subhead-4 mb-2 w-full text-start text-black-100">{{ $t('other_payment_method') }}</div>
                <div class="text-body-2 text-start text-black-100">{{ $t('other_payment_method_text') }}</div>
              </template>
            </UiInputRadio>
          </div>
        </div>
        <div class="flex w-full flex-col items-end justify-end gap-6">
          <div class="flex flex-col items-start rounded-lg bg-additional-3-10 p-3">
            <div class="text-body-2 mb-2.5 text-start">
              {{ $t('generate_payment_link_notify', { hours: holdingHours }) }}
            </div>
            <UiInputCheckbox id="agreed" v-model="paymentLinkData.agreed" name="agreed">
              <template #label>
                <div class="text-subhead-4" :class="{ 'text-error-100': useGetFieldErrors(v$, ['agreed']) }">
                  {{ $t('checkbox_agreed') }}
                </div>
              </template>
            </UiInputCheckbox>
          </div>
          <div class="flex">
            <UiButtonBase
              id="closePopup"
              type="secondary"
              class="mr-4"
              :disabled="showAddLeadForm || loading"
              @click="emits('close')"
            >
              {{ $t('cancel') }}
            </UiButtonBase>
            <UiButtonBase id="createLink" :disabled="showAddLeadForm || loading" @click="nextPage">
              {{ $t('create') }}
            </UiButtonBase>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, sameAs } from '@vuelidate/validators'
import { format } from 'date-fns'
import { useUiStore } from '~/store/ui'
import type { InputItemLead, InputItem, PaymentLinkData, Lead } from '@/types'
import { PAYMENT_TOKEN_CODES } from '~/constants'

const props = defineProps<{ unitId: number | null; loading: boolean; holdingHours: string }>()

const emits = defineEmits<{
  (event: 'close'): void
  (event: 'nextStep', paymentLinkData: PaymentLinkData): void
}>()

const uiStore = useUiStore()

const { t: $t } = useI18n()

const paymentLinkData = ref<PaymentLinkData>({
  amount: null,
  lead_id: null,
  type: null,
  unit_id: props.unitId,
  agreed: false,
})
const leads = ref<InputItemLead[]>([])
const retrieving = ref<boolean>(false)
const paymentTokenTypes = ref<InputItem[]>([])
const showAddLeadForm = ref<boolean>(false)
const leadSelect = ref(null)

const rules = computed(() => ({
  amount: { required: helpers.withMessage('The name is required', required) },
  lead_id: { required: helpers.withMessage('The name is required', required) },
  type: { required: helpers.withMessage('The name is required', required) },
  agreed: { sameAs: sameAs(true) },
}))

const v$ = useVuelidate<PaymentLinkData>(rules, paymentLinkData)

const getActiveLeads = async (query?: string) => {
  retrieving.value = true
  try {
    const { data } = await useGetActiveLeads(query)
    leads.value = data.map((l: any) => ({ text: l.name, value: l.id, ...l }))
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    retrieving.value = false
  }
}

const clickAddLeadForm = () => {
  showAddLeadForm.value = true
  leadSelect.value?.setInputFocused(false)
}

const addedNewLead = async (lead: Lead) => {
  await getActiveLeads()
  paymentLinkData.value.lead_id = lead.id
  showAddLeadForm.value = false
}

const nextPage = async () => {
  if (!(await v$.value.$validate())) return
  emits('nextStep', paymentLinkData.value)
}

onMounted(async () => {
  paymentTokenTypes.value = await usePaymentTokenTypes()
  await getActiveLeads()
})
</script>

<style scoped></style>
