<template>
  <div v-if="modelValue" class="fixed inset-x-0 top-0 z-50 size-full overflow-hidden bg-black-100/30 p-4 backdrop-blur">
    <slot name="outside" />
    <div class="m-auto flex size-full flex-col justify-center">
      <div
        id="popup"
        ref="popup"
        class="relative mx-auto flex w-full flex-col overflow-hidden rounded-2xl bg-white text-center shadow xs:px-4 xs:py-5 sm:px-6 sm:py-8"
        :class="[
          {
            'md:w-3/12': size === 'extraSmall',
            'md:w-4/12': size === 'small',
            'md:w-5/12': size === 'medium',
            'md:w-6/12': size === 'big',
            'md:w-8/12': size === 'mediumBig',
            'md:w-9/12': size === 'large',
            'md:w-10/12': size === 'extraBig',
            'h-full': fullHeight,
            '!p-0': withoutPadding,
          },
          popupClasses,
        ]"
      >
        <slot name="custom">
          <slot name="header">
            <!-- Title -->
            <div
              v-if="showHeader"
              class="z-[1] flex flex-row items-center justify-between px-3 pt-2"
              :class="{ 'border-b border-solid border-black-10 shadow': showTopOverflowBorder }"
            >
              <slot name="title">
                <div v-if="!alignTitleLeft" class="w-1/12"></div>
                <div class="w-10/12">
                  <h3
                    v-if="title"
                    :class="
                      alignTitleLeft
                        ? 'text-table-header text-left text-black-60'
                        : isMobile
                        ? 'text-caption text-black-60'
                        : ''
                    "
                  >
                    {{ title }}
                  </h3>
                </div>
              </slot>
              <div class="flex w-1/12 justify-end">
                <UiIcon v-if="!hideCloseButton" name="big-close" class="cursor-pointer" @click="handleCrossClose" />
              </div>
            </div>
          </slot>
          <!-- Content -->
          <div class="flex-1 px-3 xs:pt-4 sm:py-4" :class="{ 'overflow-y-hidden': !hidden }">
            <p v-if="description" class="whitespace-pre-line">
              {{ description }}
            </p>
            <div ref="content" class="size-full" :class="{ 'overflow-auto': !hidden }" @scroll="checkScroll">
              <slot />
            </div>
          </div>
          <!-- Actions -->
          <div
            v-if="primaryButtonText || secondaryButtonText"
            class="flex flex-row gap-4 px-3 pb-2 pt-0"
            :class="[
              primaryButtonText && secondaryButtonText ? 'pt-4' : '',
              (secondaryButtonText && !isMobile) || alignButtonsEnd ? 'justify-end' : 'justify-center',
              {
                'z-[1] border-t border-solid border-black-10  shadow-up': showBottomOverflowBorder,
              },
            ]"
          >
            <UiButtonBase
              v-if="secondaryButtonText"
              id="popup_secondary"
              type="secondary"
              :size="compact ? 'medium' : 'big'"
              class="!w-1/2 xs:!w-auto"
              @click="cancel"
            >
              {{ secondaryButtonText }}
            </UiButtonBase>
            <UiButtonBase
              v-if="primaryButtonText"
              id="popup_primary"
              :size="compact ? 'medium' : 'big'"
              :class="primaryButtonFullWidth ? 'w-full' : '!w-1/2 xs:!w-auto'"
              :disabled="loading"
              @click="confirm"
            >
              {{ primaryButtonText }}
            </UiButtonBase>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const emits = defineEmits(['update:modelValue', 'confirm', 'cancel', 'close'])

const route = useRoute()

const { smaller } = useBreakpoints(useGetBreakpoints())
const isMobile = smaller('xs')

type Props = {
  modelValue: boolean
  size?: 'extraSmall' | 'small' | 'medium' | 'big' | 'mediumBig' | 'extraBig' | 'large'
  title?: string
  description?: string
  primaryButtonText?: string
  primaryButtonFullWidth?: boolean
  hideCloseButton?: boolean
  secondaryButtonText?: string
  loading?: boolean
  closeOnClickOutside?: boolean
  fullHeight?: boolean
  alignTitleLeft?: boolean
  hidden?: boolean
  popupClasses?: string
  secondaryButtonCloses?: boolean
  showHeader?: boolean
  alignButtonsEnd?: boolean
  compact?: boolean
  withoutPadding?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  size: 'small',
  title: '',
  description: '',
  primaryButtonText: '',
  secondaryButtonText: '',
  primaryButtonFullWidth: false,
  hideCloseButton: false,
  fullHeight: false,
  alignTitleLeft: false,
  popupClasses: '',
  secondaryButtonCloses: true,
  showHeader: true,
  alignButtonsEnd: false,
  compact: false,
  withoutPadding: false,
})

let contentOverflows = ref(false)
const showTopOverflowBorder = ref(false)
const showBottomOverflowBorder = ref(false)
const content = ref<HTMLElement>()

const popup = ref<HTMLElement | null>(null)

onMounted(() => {
  contentOverflows = computed(() => Number(content?.value?.scrollHeight) > Number(content?.value?.offsetHeight))
  setTimeout(() => {
    checkScroll()
  })
})

const checkScroll = () => {
  if (contentOverflows.value) showTopOverflowBorder.value = Number(content.value?.scrollTop) > 0
  showBottomOverflowBorder.value =
    Math.abs(
      Number(content.value?.scrollHeight) - Number(content.value?.scrollTop) - Number(content.value?.clientHeight)
    ) > 1
}

const cancel = () => {
  emits('cancel')
  if (props.secondaryButtonCloses) emits('update:modelValue', false)
}

const confirm = () => {
  emits('confirm')
}

onNuxtReady(() => {
  onClickOutside(popup.value, () => {
    if (props.closeOnClickOutside) {
      emits('update:modelValue', false)
    }
  })
})

const isPopupMounted = useState('isPopupMounted', () => false)

const handleCrossClose = () => {
  emits('update:modelValue', false)
  emits('close')
}

watch(
  () => route.name,
  () => {
    if (props.modelValue) {
      emits('update:modelValue', false)
    }
  }
)

onMounted(() => {
  isPopupMounted.value = true
})

onUnmounted(() => {
  isPopupMounted.value = false
})
</script>

<style scoped></style>
