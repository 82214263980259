<template>
  <div class="rounded-b-2xl bg-white px-3 py-4 md:rounded-2xl md:p-8">
    <ClientOnly>
      <div class="mb-4 flex gap-4">
        <UiAvatar
          :user="user"
          class="size-[70px] min-w-[70px] md:size-28 md:min-w-28"
          classes="rounded-lg h-full w-full text-subhead-1 !text-3xl size-[70px] md:size-28"
        />
        <div class="flex w-full flex-col gap-2">
          <PropertyTabsInfoCard v-if="!isMobile" compact>
            <p class="flex items-center gap-3">
              <UiIcon name="user-circle" class="" :size="isMobile ? 'xxs' : 'xs'" /> {{ user.name }}
            </p>
          </PropertyTabsInfoCard>
          <PropertyTabsInfoCard v-if="user.phones.length && !isMobile" compact>
            <a id="call-agent" :href="`tel:${phone}`" class="text-body-2 flex items-center gap-3">
              <UiIcon name="call" :size="isMobile ? 'xxs' : 'xs'" />+{{ phone }}
            </a>
          </PropertyTabsInfoCard>
          <PropertyTabsInfoCard v-if="isMobile" @click="sendWhatsAppMessage()">
            <div class="flex flex-row items-center gap-3">
              <UiIcon name="whatsapp-icon" class="text-whatsapp" :size="isMobile ? 'xs' : 'sm'" />
              +{{ phone }}
            </div>
          </PropertyTabsInfoCard>
          <PropertyTabsInfoCard v-if="user.email" :compact="!isMobile">
            <a id="email-agent" :href="`mailto:${user.email}`" class="text-body-2 flex items-center gap-3">
              <UiIcon name="mail" :size="isMobile ? 'xxs' : 'xs'" />
              {{ user.email }}
            </a>
          </PropertyTabsInfoCard>
        </div>
      </div>
      <div class="flex gap-4">
        <UiButtonBase v-if="!isMobile" id="whatsapp-agent" icon type="success" @click="sendWhatsAppMessage()"
          ><UiIcon name="whatsapp-icon" class="!size-7"
        /></UiButtonBase>
        <UiButtonBase
          id="call-agent"
          class="text-subhead-3 w-full md:text-base"
          :size="isMobile ? 'small' : 'medium'"
          @click="call()"
          >Call agent</UiButtonBase
        >
      </div>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import type { User } from '@/types'
type Props = {
  user: User
  isMobile?: boolean
}

const props = defineProps<Props>()
const phone = computed(() => `${props.user.phones[0].code}${props.user.phones[0].phone}`)

const sendWhatsAppMessage = () => {
  navigateTo(`https://api.whatsapp.com/send?phone=${phone.value}&text=Hey ${props.user.name},`, {
    external: true,
    open: {
      target: '_blank',
    },
  })
}
const call = () => {
  const link = document.createElement('a')
  link.href = `tel:${phone.value}`
  link.click()
}
</script>

<style scoped></style>
