<template>
  <UiSidePanelForm
    :model-value="modelValue"
    title="Source details"
    description="Edit the source details for a lead. Changes will reflect in the lead's profile and associated analytics."
    primary-button-text="Save"
    secondary-button-text="Cancel"
    :loading="loading"
    :disabled="saving"
    @update:model-value="$emit('update:modelValue', false)"
    @confirm="submit"
  >
    <div>
      <form class="h-full" @submit.prevent>
        <UiInputSelect
          v-model="form.is_digital"
          :items="digitalItems"
          label="Digital"
          name="Digital"
          placeholder="Choose digital"
          class="mb-4"
        />
        <UiInputSelect
          v-model="form.utm_source"
          :items="useSerializeLibraryItems(librariesStore.getUtmSources)"
          label="Channel"
          name="Channel"
          placeholder="Choose channel"
          class="mb-4"
        />
        <UiInputSelect
          v-model="form.source_id"
          :items="sourceItems"
          label="Source"
          name="Source"
          placeholder="Type or select from the list"
          class="mb-4"
          add-new
          @update:model-value="addCustomValue($event)"
          @updated:shallow-value="getSources($event)"
        />
        <UiInputSelect
          v-model="form.utm_campaign"
          :items="campaignsItems"
          label="Campaign name"
          name="Campaign"
          placeholder="Choose campaign"
          class="mb-4"
        />
        <UiInputSelect
          v-model="form.utm_project"
          :items="projectItems"
          label="Project name"
          name="Project"
          placeholder="Choose project"
          class="mb-4"
        />
        <div>
          <h5 class="text-subhead-1 mb-4 text-black-60">Place lead into</h5>
          <UiInputRadio
            id="choose_method"
            v-model="form.save_strategy"
            :items="useSerializeLibraryItems(librariesStore.getSourceLeadStrategies)"
            class="mb-4"
            vertical
          />
          <UiInputSelect
            v-if="isNewUserStrategy"
            v-model="form.user_id"
            :items="userItems"
            name="Users"
            group
            avatar
            placeholder="Select user"
            :error="useGetFieldErrors(v$, ['user_id'])"
          />
        </div>
      </form>
    </div>
  </UiSidePanelForm>
</template>

<script setup lang="ts">
import omitBy from 'lodash/omitBy'
import { useVuelidate } from '@vuelidate/core'
import { helpers, requiredIf } from '@vuelidate/validators'
import type { InputItem, Lead, LibraryItem } from '@/types'
import { useUiStore } from '~/store/ui'
import { useLibrariesStore } from '~/store/libraries'

const STRATEGIES = {
  CURRENT: 0,
  NEW_USER: 1,
  ROTATION: 2,
}

const uiStore = useUiStore()
const librariesStore = useLibrariesStore()

const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
  lead: Lead
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})
const digitalItems: InputItem[] = [
  { text: 'Yes', value: true },
  { text: 'No', value: false },
]

const loading = ref(true)
const saving = ref(false)
const sourceItems = ref<InputItem[]>([])
const userItems = ref<InputItem[]>([])
const campaignsItems = ref<InputItem[]>([])
const projectItems = ref<InputItem[]>([])

type sourceForm = {
  is_digital: boolean
  source_id: number | null
  utm_source?: string
  utm_campaign?: string
  utm_project?: string
  save_strategy: number
  user_id?: number
}

const form = ref<sourceForm>({
  is_digital: props.lead?.is_digital || false,
  utm_source: props.lead?.campaign?.utm_source || '',
  source_id: props.lead?.source?.id || null,
  utm_campaign: props.lead?.campaign?.utm_campaign || '',
  utm_project: props.lead?.campaign?.utm_project || '',
  save_strategy: STRATEGIES.CURRENT,
  user_id: undefined,
})

const isNewUserStrategy = computed(() => form.value.save_strategy === STRATEGIES.NEW_USER)

onNuxtReady(async () => {
  loading.value = true
  await Promise.all([
    getUsers(),
    useSourceLeadStrategies(),
    useUtmSources(),
    getCampaigns(),
    getSources(),
    getProjects(),
  ])
  loading.value = false
})

const rules = computed(() => ({
  user_id: {
    requiredIfValue: helpers.withMessage('User is required', requiredIf(isNewUserStrategy.value)),
  },
}))

const v$ = useVuelidate(rules, form)

const getCampaigns = async () => {
  try {
    const { data } = await useGetCampaigns()
    campaignsItems.value = data.map((a: LibraryItem) => ({ value: a.name, text: a.name })) as InputItem[]
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

const getUsers = async () => {
  try {
    userItems.value = await useGetUsersItems(true)
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

const getSources = async (query?: string) => {
  try {
    sourceItems.value = await useGetLeadSourcesItems(query)
    const leadSource = sourceItems.value.find((s: InputItem) => s.value === props.lead?.source?.id)
    if (!leadSource && !query) {
      sourceItems.value.unshift({ text: props.lead?.source?.name, value: props.lead?.source?.id })
    }
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

const addCustomValue = async (value: string) => {
  try {
    if (!sourceItems.value.length) {
      const { data } = await useAddLeadSource(value)
      sourceItems.value.unshift({ text: data.name, value: data.id })
      form.value.source_id = data.id
    }
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

const getProjects = async () => {
  try {
    const projects = await useGetPropertyProjects()
    projectItems.value = useSerializeLibraryItems(projects)
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

const submit = async () => {
  const isValid = await v$.value.$validate()
  if (!isValid) return

  saving.value = true
  const result = omitBy(form.value, (v) => v === null || v === undefined)
  try {
    await useUpdateSourceLead(props.lead.id, result)
    uiStore.showSnackBanner('Lead updated successfully')
    emits('input')
    emits('update:modelValue', false)
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    saving.value = false
  }
}
</script>

<style scoped></style>
