import { useAuthStore } from '@/store/auth'
import { useUiStore } from '@/store/ui'
import type { ApiResponse, PaginationResponse } from '@/types'

export const useGetNotifications = async (query: Object = {}) => {
  const authStore = useAuthStore()

  const { data, error } = await useAPIFetch<PaginationResponse>(`/notifications/users/${authStore.getUser?.id}`, {
    query,
    stringifyOptions: { arrayFormat: 'comma' },
  })

  if (error.value) throw createError(error.value)

  return data.value
}

export const useGetUnreadNotificationsCount = async () => {
  const authStore = useAuthStore()
  const uiStore = useUiStore()
  const { data, error } = await useAPIFetch(`/notifications/users/${authStore.getUser?.id}/unread/count`)
  if (error.value) throw createError(error.value)

  uiStore.setHasNewNotifications(!!data.value?.data?.count)

  return data.value as ApiResponse
}

export const useSetNotificationRead = async (notificationId: number) => {
  const { data, error } = await useAPIFetch(`/notifications/${notificationId}/read`, { method: 'PATCH' })
  if (error.value) throw createError(error.value)

  return data.value
}

export const useSetNotificationUnread = async (notificationId: number) => {
  const { data, error } = await useAPIFetch(`/notifications/${notificationId}/unread`, { method: 'PATCH' })
  if (error.value) throw createError(error.value)

  return data.value
}

export const useSetAllNotificationRead = async () => {
  const { data, error } = await useAPIFetch(`/notifications/read`, { method: 'PATCH' })
  if (error.value) throw createError(error.value)

  return data.value
}
