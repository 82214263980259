import {
  STAGES,
  ARCHIVES_STAGES,
  CLOSE_STEPS,
  CLOSED_LOST_STEPS,
  ARCHIVE_LOST_STEPS,
  LEAD_PHONE_TYPES_CODES,
  TAGS,
} from '~/constants'
import { useLibrariesStore } from '~/store/libraries'
import { useUiStore } from '~/store/ui'
import type { Lead } from '~/types'

const useLeadStatus = (lead: Ref<Lead | undefined>) => {
  const isShadow = computed(() => {
    return Boolean(lead.value?.parent_id)
  })

  const isInArchive = computed(() => {
    if (!lead.value?.stage.code) return false

    const array = [Object.values(ARCHIVES_STAGES), Object.values(ARCHIVE_LOST_STEPS)].flat()

    if (!array.includes(lead.value.stage.code)) {
      return false
    }

    if (!lead.value.step?.code) return false

    return array.includes(lead.value.step?.code)
  })

  const isClosedWon = computed(() => {
    if (!lead.value?.stage.code) return false

    if (![STAGES.CLOSED, ARCHIVES_STAGES.WON].includes(lead.value.stage.code)) {
      return false
    }

    return lead.value.step?.code === CLOSE_STEPS.WON || lead.value.step?.code === ARCHIVES_STAGES.WON
  })

  const isClosedLost = computed(() => {
    if (!lead.value?.stage.code) return false

    if (![STAGES.CLOSED, ARCHIVES_STAGES.LOST].includes(lead.value.stage.code)) {
      return false
    }

    if (!lead.value.step?.code) return false

    const array = [Object.values(CLOSED_LOST_STEPS), Object.values(ARCHIVE_LOST_STEPS)].flat()

    return array.includes(lead.value.step?.code)
  })

  const isClosed = computed(() => {
    return isClosedWon.value || isClosedLost.value
  })

  const librariesStore = useLibrariesStore()

  const communicationWay = computed(() => {
    if (!librariesStore.getLeadPhoneTypes.length || !lead.value?.communication_way_id) return null

    return librariesStore.getLeadPhoneTypes.find((item) => item.id === lead.value?.communication_way_id) || null
  })

  const isCommunicationWayPartner = computed(() => {
    if (!communicationWay.value) return false

    return communicationWay.value.code === LEAD_PHONE_TYPES_CODES.VIA_PARTNER
  })

  const isCommunicationWayRepresentative = computed(() => {
    if (!communicationWay.value) return false

    return communicationWay.value.code === LEAD_PHONE_TYPES_CODES.VIA_REPRESENTATIVE
  })

  const isPhoneWayPartner = computed(() => {
    if (!lead.value?.phone_via) return false
    return lead.value.phone_via.code === LEAD_PHONE_TYPES_CODES.VIA_PARTNER
  })

  const isPhoneWayRepresentative = computed(() => {
    if (!lead.value?.phone_via) return false
    return lead.value.phone_via.code === LEAD_PHONE_TYPES_CODES.VIA_REPRESENTATIVE
  })

  const isReferralLead = computed(() => {
    if (!lead.value?.tags) return false
    return !!lead.value.tags.find((t) => t.code === TAGS.REFERRAL)
  })

  const uiStore = useUiStore()
  onNuxtReady(async () => {
    try {
      await useLeadPhoneTypes()
    } catch (error: any) {
      uiStore.showSnackBanner(error.message, 'error')
    }
  })

  return {
    isShadow,
    isClosedWon,
    isClosedLost,
    isClosed,
    isInArchive,
    isCommunicationWayPartner,
    isCommunicationWayRepresentative,
    isPhoneWayPartner,
    isPhoneWayRepresentative,
    isReferralLead,
  }
}

export default useLeadStatus
