<template>
  <div class="lg:h-[210px]">
    <div v-for="(deal, i) in modelValue.deals" :key="i">
      <Transition name="fade" mode="out-in">
        <div
          v-show="activeTabIdIndex === i"
          class="grid size-full grid-flow-col-dense grid-cols-1 grid-rows-6 gap-3 lg:grid-cols-2 lg:grid-rows-3"
        >
          <UiInputSelect
            :id="`${i}_project`"
            v-model="deal.project_id"
            :items="projectItems"
            add-new
            name="project"
            :placeholder="$t('select_from_list_or_type')"
            :label="$t('project') + ' *'"
            compact
            :error="useGetFieldErrorsByIndex(v, 'deals', 'project_id', i)"
            @update:model-value="addCustomProject($event, i)"
          />
          <UiInputTextField
            :id="`${i}_unit_number`"
            v-model="deal.unit_number"
            :placeholder="$t('add_unit_number')"
            name="unit_number"
            :label="$t('unit_number') + ' *'"
            compact
            :error="useGetFieldErrorsByIndex(v, 'deals', 'unit_number', i)"
          />
          <UiInputTextField
            :id="`${i}_brokerage_commission`"
            v-model="deal.commission.percent"
            :placeholder="$t('add_commission')"
            type="percentage"
            suffix="%"
            name="brokerage_commissions"
            :label="$t('brokerage_commission') + ' *'"
            compact
            :error="useGetFieldErrorsByIndex(v, 'deals', 'commission', i)"
          />
          <UiInputSelect
            :id="`${i}_developer_id`"
            v-model="deal.developer_id"
            :items="developerItems"
            add-new
            name="developer_id"
            :placeholder="$t('select_from_list_or_type')"
            :label="$t('developer') + ' *'"
            compact
            :error="useGetFieldErrorsByIndex(v, 'deals', 'developer_id', i)"
            @update:model-value="addCustomDeveloper($event, i)"
          />
          <UiInputTextField
            :id="`${i}_unit_price`"
            v-model="deal.unit_price"
            :placeholder="$t('unit_price')"
            type="number"
            suffix="AED"
            name="unit_price"
            :label="$t('unit_price') + ' *'"
            compact
            :error="useGetFieldErrorsByIndex(v, 'deals', 'unit_price', i)"
          />
          <UiInputDatePicker
            v-model="deal.booking_date"
            name="dateRange"
            :start-with-placeholder="true"
            :placeholder="$t('select_date')"
            :label="$t('booking_date') + '*'"
            compact
            :error="useGetFieldErrorsByIndex(v, 'deals', 'booking_date', i)"
          />
        </div>
      </Transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Validation } from '@vuelidate/core'
import { useUiStore } from '~/store/ui'
import type { DealCreationDTO, InputItem } from '~/types'

const uiStore = useUiStore()

type Props = {
  v: Validation
  activeTabIdIndex?: number
}

defineProps<Props>()

const modelValue = defineModel<Partial<DealCreationDTO>>({ required: true })

const projectItems = ref<InputItem[]>([])
const developerItems = ref<InputItem[]>([])

onNuxtReady(async () => {
  try {
    await Promise.all([getProjectItems(), getDeveloperItems()])
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
})

const getProjectItems = async () => {
  projectItems.value = await useGetFinanceProjectsItems()
}

const getDeveloperItems = async () => {
  developerItems.value = await useGetFinanceDevelopersItems()
}

const addCustomProject = async (name: string, index: number) => {
  if (!projectItems.value.find((d) => d.value === name)) {
    try {
      const response = await useAddDealProject(name)
      projectItems.value.unshift({ text: response.data.name, value: response.data.id })
      modelValue.value.deals[index].project_id = response.data.id
    } catch (err: any) {
      uiStore.showSnackBanner('Failed to add custom project', 'error')
    }
  }
}

const addCustomDeveloper = async (name: string, index: number) => {
  if (!developerItems.value.find((d) => d.value === name)) {
    try {
      const response = await useAddDealDeveloper(name)
      developerItems.value.unshift({ text: response.data.name, value: response.data.id })
      modelValue.value.deals[index].developer_id = response.data.id
    } catch (err: any) {
      uiStore.showSnackBanner('Failed to add custom developer', 'error')
    }
  }
}
</script>

<style scoped></style>
