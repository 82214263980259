<template>
  <div class="h-full overflow-y-hidden">
    <div class="absolute right-0 top-0 flex h-full w-1/2 flex-col bg-black-03"></div>
    <div class="relative z-10 flex h-full flex-col overflow-y-auto text-left">
      <div class="mb-4 flex w-full flex-row">
        <div class="flex w-1/2 flex-col gap-4 pr-5">
          <div>
            <p class="text-subhead-2 mb-2">Send to Queue</p>
            <div class="rounded-lg bg-black-03 p-3">
              <UiInputCheckbox id="call_queue" v-model="modelValue.call_queue" name="call_queue" label="Call queue" />
              <Transition name="fade" mode="out-in">
                <div v-if="modelValue.call_queue" class="mt-2 pl-6">
                  <UiInputRadio id="call_type" v-model="callSettings.callQueueHandlerId" :items="callItems" vertical />
                </div>
              </Transition>
            </div>
          </div>
          <template v-if="modelValue.call_queue">
            <div>
              <p class="text-subhead-2 mb-2">Set up a schedule:</p>
              <div class="flex flex-col gap-3 rounded-lg bg-black-03 p-3">
                <div class="flex items-center gap-1">
                  <p class="text-caption min-w-[84px] text-black-60">Working days</p>
                  <div class="flex w-full items-center gap-1">
                    <UiInputOptions
                      id="workingDay"
                      v-model="callSettings.workingDay"
                      name="workingDay"
                      class="w-full"
                      :items="workingDays"
                      multiple
                    />
                  </div>
                </div>
                <hr class="border-black-20" />
                <div class="flex items-center gap-1">
                  <p class="text-caption min-w-[84px] text-black-60">Working hours</p>
                  <div class="flex items-center gap-1">
                    <UiInputSelect
                      :model-value="callSettings.workingHours.from.text"
                      class="!w-[100px]"
                      :items="timeOptionsFrom"
                      :hide-arrow="true"
                      compact
                      name="workingHoursFrom"
                      placeholder="00:00"
                      @update:model-value="setTime($event, 'from')"
                    />
                    <span class="text-subhead-4 text-black-90">-</span>
                    <UiInputSelect
                      :model-value="callSettings.workingHours.to.text"
                      class="!w-[100px]"
                      :items="timeOptionsTo"
                      :hide-arrow="true"
                      compact
                      name="workingHoursTo"
                      placeholder="00:00"
                      @update:model-value="setTime($event, 'to')"
                    />
                  </div>
                </div>
                <div class="flex items-center gap-1">
                  <p class="text-caption min-w-[84px] text-black-60">Timezone</p>
                  <div class="flex flex-row items-center gap-x-3">
                    <UiInputSelect
                      v-model="callSettings.workingTimezoneId"
                      :items="timezoneItems"
                      align-right
                      name="Timezone"
                      compact
                      class="!w-72"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p class="text-subhead-2 mb-2">Set a delay</p>
              <div class="rounded-lg bg-black-03 p-3">
                <UiInputCheckbox
                  id="setDelay"
                  v-model="callSettings.setDelay"
                  name="setDelay"
                  label="Send leads for a call with delay"
                />
                <Transition name="fade" mode="out-in">
                  <div v-if="callSettings.setDelay">
                    <UiInputTextField
                      v-model="callSettings.delay"
                      name="delay"
                      type="number"
                      compact
                      placeholder="20"
                      class="mt-2 !w-40"
                      suffix="min"
                    />
                  </div>
                </Transition>
              </div>
            </div>
          </template>
        </div>
        <div class="flex w-1/2 flex-col gap-4 pl-8 text-left">
          <div class="rounded-lg border border-dm-40 px-4 py-3">
            <p class="text-subhead-2 mb-2">Name:</p>
            <p class="text-subhead-3">{{ modelValue.name }}</p>
            <hr class="my-3 border-black-20" />
            <p class="text-subhead-2 mb-2">Parameters:</p>
            <RotationMappingPreview :rules="formattedRules" class="!gap-3" />
          </div>
          <div v-if="modelValue.call_queue" class="flex flex-col gap-3 rounded-lg border border-dm-40 px-4 py-3">
            <div class="flex flex-col gap-2 border-b border-dm-40 pb-3 last:border-b-0 last:pb-0">
              <p class="text-subhead-2">Send to Queue</p>
              <p class="text-body-2">
                Call queue: <span class="text-subhead-3">{{ getChosenCallHandler }}</span>
              </p>
              <p class="text-body-2">
                Schedule:
                <span class="text-subhead-3"
                  >{{ getChosenWorkingDays }} • {{ callSettings.workingHours.from.text }}-{{
                    callSettings.workingHours.to.text
                  }}</span
                >
              </p>
              <p v-if="callSettings.setDelay" class="text-body-2">
                Delay: <span class="text-subhead-3">{{ callSettings.delay }} min</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-auto flex flex-row items-center gap-4">
        <div class="ml-auto flex flex-row items-center gap-4">
          <UiButtonBase id="cancel" type="secondary" @click="emits('back')">
            <UiIcon name="chevron-big-left" />
            Back to edit
          </UiButtonBase>
          <UiButtonBase id="submit" @click="goNextStep">Next</UiButtonBase>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUiStore } from '~/store/ui'
import type { Mapping, InputItem } from '~/types'
import { SETTING_CODES } from '@/constants'

const uiStore = useUiStore()

const emits = defineEmits(['back', 'next'])

type Props = {
  ruleToEdit?: Mapping
}

const props = withDefaults(defineProps<Props>(), {
  ruleToEdit: undefined,
})

const modelValue = defineModel<Mapping>({ required: true })

const callSettings = ref({
  workingDay: [],
  workingHours: {
    from: {
      text: '9:00 AM',
      value: {
        hours: '09',
        minutes: '00',
      },
    },
    to: {
      text: '5:00 PM',
      value: {
        hours: '17',
        minutes: '00',
      },
    },
  },
  workingTimezoneId: 0,
  setDelay: false,
  delay: 20,
  callQueueHandlerId: undefined,
})

const callItems = ref<InputItem[]>([])

const formattedRules = computed(() => [...modelValue.value.rules, ...modelValue.value.rulesOr!])

const getChosenCallHandler = computed(() => {
  return callItems.value.find((item: InputItem) => item.value === callSettings.value.callQueueHandlerId)?.text
})

const getChosenWorkingDays = computed(() => {
  return workingDays
    .filter((day: InputItem) => callSettings.value.workingDay.includes(day.value))
    .map((day: InputItem) => day.text)
    .join(', ')
})

const timeOptions = (() => {
  const result = []
  for (let hours = 0; hours < 24; hours++) {
    result.push({
      text: `${hours <= 12 ? (hours === 0 ? hours + 12 : hours) : hours - 12}:00 ${hours >= 12 ? 'PM' : 'AM'}`,
      value: {
        hours: `${String(hours).length > 1 ? hours : '0' + hours}`,
        minutes: '00',
      },
    })
  }
  return result
})()
const timeOptionsFrom = timeOptions.slice(0, timeOptions.length - 1)
const timeOptionsTo = computed(() =>
  timeOptions.filter((t) => {
    return +t.value.hours > +callSettings.value.workingHours.from.value.hours
  })
)
const timezoneItems = ref<InputItem[]>([])
const workingDays: InputItem[] = [
  { text: 'Mon', value: 1 },
  { text: 'Tue', value: 2 },
  { text: 'Wed', value: 3 },
  { text: 'Thu', value: 4 },
  { text: 'Fri', value: 5 },
  { text: 'Sat', value: 6 },
  { text: 'Sun', value: 7 },
]

onNuxtReady(async () => await Promise.all([getCallHandlers(), getCallSettings(), getTimezones()]))

const getTimezones = async () => {
  try {
    const data = await useTimezones()
    timezoneItems.value = data.map((t) => ({ text: `${t.offset} ${t.name}`, value: t.id, ...t }))
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

const getCallHandlers = async () => {
  try {
    const data = useSerializeLibraryItems(await useCallHandlers())
    callItems.value = data.reverse()
    if (!modelValue.value.call_queue_handler_id) {
      callSettings.value.callQueueHandlerId = callItems.value[0].value
    } else {
      callSettings.value.callQueueHandlerId = modelValue.value.call_queue_handler_id
    }
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

const setTime = (time: any, type: string) => {
  const timeDate = timeOptions.find((t) => t.value.hours === time.hours)
  if (!timeDate) return

  const value = callSettings.value.workingHours

  type === 'from' ? (value.from = timeDate) : (value.to = timeDate)
  if (type !== 'to' && +time.hours >= +value.to.value.hours) {
    const currentTimeIndex = timeOptions.findIndex((t) => t.value === time)
    value.to = timeOptions[currentTimeIndex + 1]
  }
}

const getCallSettings = async () => {
  try {
    if (props.ruleToEdit && modelValue.value.call_queue && modelValue.value.working_day.length) {
      const {
        call_queue_handler_id: callQueueHandlerId,
        working_day: workingDay,
        working_hours_from: workingHoursFrom,
        working_hours_to: workingHoursTo,
        timezone_id: workingTimezoneId,
      } = props.ruleToEdit
      callSettings.value = {
        callQueueHandlerId,
        workingDay,
        workingTimezoneId,
        workingHours: {
          from: timeOptions.find((t) => `${t.value.hours}:${t.value.minutes}` === workingHoursFrom)!,
          to: timeOptions.find((t) => `${t.value.hours}:${t.value.minutes}` === workingHoursTo)!,
        },
      }
      if (props.ruleToEdit.delay) {
        callSettings.value.setDelay = true
        callSettings.value.delay = props.ruleToEdit.delay
      }
    } else {
      const codes = {
        code: [
          SETTING_CODES.WORKING_HOURS_FROM,
          SETTING_CODES.WORKING_HOURS_TO,
          SETTING_CODES.WORKING_DAYS,
          SETTING_CODES.WORKING_TIMEZONE,
        ],
      }
      const data: Record<string, string> = await useGetSettingGroupByCodes(codes)
      callSettings.value.workingHours.from = timeOptions.find(
        (t) => `${t.value.hours}:${t.value.minutes}` === data[SETTING_CODES.WORKING_HOURS_FROM]
      )!
      callSettings.value.workingHours.to = timeOptions.find(
        (t) => `${t.value.hours}:${t.value.minutes}` === data[SETTING_CODES.WORKING_HOURS_TO]
      )!
      callSettings.value.workingDay = data[SETTING_CODES.WORKING_DAYS].split(',').map(Number)
      callSettings.value.workingTimezoneId = Number(data[SETTING_CODES.WORKING_TIMEZONE])
    }
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

const goNextStep = () => {
  if (modelValue.value.call_queue) {
    const { callQueueHandlerId, workingDay, workingHours, workingTimezoneId, delay, setDelay } = callSettings.value
    Object.assign(modelValue.value, {
      call_queue_handler_id: callQueueHandlerId,
      working_day: workingDay,
      working_hours_from: `${workingHours.from.value.hours}:${workingHours.from.value.minutes}`,
      working_hours_to: `${workingHours.to.value.hours}:${workingHours.to.value.minutes}`,
      timezone_id: workingTimezoneId,
    })
    if (setDelay && delay) {
      modelValue.value.delay = delay
    } else {
      delete modelValue.value.delay
    }
  } else if (props.ruleToEdit) {
    const keysToDelete = [
      'call_queue_handler_id',
      'working_day',
      'working_hours_from',
      'working_hours_to',
      'timezone_id',
      'delay',
    ]
    keysToDelete.forEach((key) => delete modelValue.value[key])
  }
  emits('next')
}
</script>

<style scoped></style>
