import { parsePhoneNumber, getRegionCodeForCountryCode } from 'awesome-phonenumber'
import { useLibrariesStore } from '@/store/libraries'

export default (value?: string | null, countryId?: number | null) => {
  if (!value || !countryId) return false

  // For some reason parsePhoneNumber returns as valid phone numbers with chars inside.
  // This guarantees that only numbers will go through
  const phoneNumber = Number(value)

  const librariesStore = useLibrariesStore()
  const countries = librariesStore.getCountries

  const countryCode = Number(countries.find((c) => c.id === countryId)?.phone_code)
  if (!countryCode) return false

  const regionCode = getRegionCodeForCountryCode(countryCode)

  const phone = parsePhoneNumber(phoneNumber.toString(), { regionCode })

  return phone.valid
}
