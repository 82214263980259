<template>
  <div class="flex flex-col gap-4">
    <h5>Unit Info</h5>
    <Transition name="fade" mode="out-in">
      <div v-if="!modelValue.type_id" class="flex h-[284px] flex-col gap-2 rounded-lg bg-secondary-10 p-2" />
      <DealCreateUnitInfoOffPlan v-else-if="isOffPlan" v-model="modelValue" :active-tab-id-index :v />
      <DealCreateUnitInfoSecondary v-else v-model="modelValue" :v />
    </Transition>
  </div>
</template>

<script setup lang="ts">
import type { Validation } from '@vuelidate/core'
import type { DealCreationDTO } from '~/types'

type Props = {
  v: Validation
  activeTabIdIndex?: number
  isOffPlan?: boolean
}

defineProps<Props>()

const modelValue = defineModel<Partial<DealCreationDTO>>({ required: true })
</script>

<style scoped></style>
