<template>
  <UiPopup
    :model-value="modelValue"
    title="Change deal type"
    description="If you change the deal type now, all data from the current form will be lost. Are you sure you want to select a different type?"
    primary-button-text="Change type"
    secondary-button-text="Close"
    size="small"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="submit"
    @cancel="emits('cancel')"
  >
  </UiPopup>
</template>

<script setup lang="ts">
const emits = defineEmits(['update:modelValue', 'input', 'cancel'])

type Props = {
  modelValue: boolean
}

withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const submit = () => {
  emits('input')
  emits('update:modelValue', false)
}
</script>

<style scoped></style>
